import { useMutation } from "@tanstack/react-query";
import { updateRestaurantHours } from "../../services/restaurants";

import { Day } from "../../features/Stores/types";

export default function useUpdateHours() {
  return useMutation(
    ({ restaurantId, days }: { restaurantId: string; days: Day[] }) =>
      updateRestaurantHours(restaurantId, days)
  );
}
