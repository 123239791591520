import { CapacityScheduleInterface } from "../types";
export default function isSameCapacity(
  schedule: CapacityScheduleInterface[] | undefined
): boolean {
  if (!schedule || schedule.length === 0) {
    return true;
  }

  if (schedule && schedule.length < 7) {
    return false;
  }

  let sameHours = true;
  if (schedule) {
    let start = schedule[0].startTime;
    let end = schedule[0].endTime;
    let capacity = schedule[0].capacity;
    for (let i = 1; i < schedule.length; i++) {
      if (
        start !== schedule[i].startTime ||
        capacity !== schedule[i].capacity ||
        end !== schedule[i].endTime
      ) {
        sameHours = false;
        break;
      }
    }
  }

  return sameHours;
}
