import { useMemo } from "react";
import { Field, Form } from "react-final-form";
import Button from "../../../../components/Buttons/Button";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SidePanel from "../../../../components/SidePanel";
import SidePanelContent from "../../../../components/SidePanel/SidePanelContent";
import SidePanelFooter from "../../../../components/SidePanel/SidePanelFooter";
import SidePanelHeader from "../../../../components/SidePanel/SidePanelHeader";
import SidePanelLayout from "../../../../components/SidePanel/SidePanelLayout";
import Switch from "../../../../components/Switch/Switch";
import { useValidationSchema } from "../../../../hooks/validations/useValidationSchema";
import { DAYS_OF_WEEK_MAP, STORE_QUERY_KEY } from "../../constants";
import { StoreInterface } from "../../types";
import generateTimeSlots from "../../utils/generateTimeSlots";
import RestaurantSameHours from "../RestaurantSameHours";
import arrayMutators from "final-form-arrays";
import RestaurantDailyHours from "../RestaurantDailyHours";
import validateMobileHoursSchema from "../../validation/validateMobileHours";
import isSameHours from "../../utils/isSameHours";
import useUpdateHours from "../../../../hooks/restaurants/useUpdateHours";
import { Day } from "../../types";
import toast from "react-hot-toast";
import FormControl from "../../../../components/Forms/FormControl";
import { useQueryClient } from "@tanstack/react-query";

interface RestaurantMobileHoursProps {
  open: boolean;
  store: StoreInterface;
  orgId: string | undefined;
  onClose: () => void;
}

type HoursSubmitValues = {
  same_hours_active: boolean;

  same_hours?: {
    active?: boolean;
    startTime?: string;
    endTime?: string;

    days: Day[];
  };

  daily_hours?: {
    days: Day[];
  };
};

const timeSlots = generateTimeSlots({
  start: 0,
  end: 24,
  interval: 15,
  displayTimeFormat: "hh:mm a",
  valueTimeFormat: "HH:mm",
});

export default function RestaurantMobileHours({
  open,
  orgId,
  store,
  onClose,
}: RestaurantMobileHoursProps) {
  const queryClient = useQueryClient();

  const validateMobileHours = useValidationSchema(validateMobileHoursSchema);

  const timeSlotsAsOptions = useMemo(
    () =>
      timeSlots.map((slot) => ({
        label: slot.displayValue,
        value: slot.value,
      })),
    []
  );

  const mutateHours = useUpdateHours();

  async function onSubmit(values: HoursSubmitValues) {
    return await mutateHours.mutateAsync(
      {
        restaurantId: store?.id,
        days: values.same_hours_active
          ? values.same_hours!.days.map((day) => ({
              ...day,
              active: values.same_hours?.active === false ? false : day.active,
              shifts: values.same_hours?.active === false ? [] : day.shifts,
            }))
          : values.daily_hours!.days.map((day) => ({
              ...day,
              shifts: day.active ? day.shifts : [],
            })),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([STORE_QUERY_KEY, orgId, store.id]);
          onClose();
          toast.success("Mobile hours updated successfully");
        },
        onError: () => {
          toast.error("Failed to update Mobile hours. Please try again.");
        },
      }
    );
  }

  const restaurantDaysLookup: Record<number, any> = useMemo(() => {
    return store.days.reduce((next, item) => {
      return { ...next, [item.day]: item };
    }, {});
  }, [store]);

  const sameHours = useMemo(() => store && isSameHours(store.days), [store]);

  const initialValues = useMemo(() => {
    const prefilledDays = DAYS_OF_WEEK_MAP.map((day) => {
      return {
        day: day.value,
        dayName: day.name,
        dayShortName: day.shortName,
        active: restaurantDaysLookup[day.value]
          ? restaurantDaysLookup[day.value].active
          : false,
        shifts:
          restaurantDaysLookup[day.value] &&
          restaurantDaysLookup[day.value].shifts.length
            ? restaurantDaysLookup[day.value].shifts
            : [
                {
                  startTime: undefined,
                  endTime: undefined,
                },
              ],
      };
    });
    const emptySameHoursDays = DAYS_OF_WEEK_MAP.map((day) => ({
      day: day.value,
      dayName: day.name,
      dayShortName: day.shortName,
      active: false,
      shifts: [
        {
          startTime: undefined,
          endTime: undefined,
        },
      ],
    }));

    const emptyDailyHoursDays = DAYS_OF_WEEK_MAP.map((day) => ({
      day: day.value,
      dayName: day.name,
      dayShortName: day.shortName,
      active: true,
      shifts: [
        {
          startTime: undefined,
          endTime: undefined,
        },
      ],
    }));
    const atleastOneDayActive =
      store.days.length === 0 ? true : store.days.find((day) => day.active);

    return {
      same_hours_active: sameHours,
      same_hours: {
        active: !!atleastOneDayActive,
        startTime:
          sameHours && restaurantDaysLookup[1]
            ? restaurantDaysLookup[1].shifts[0]
              ? restaurantDaysLookup[1].shifts[0].startTime
              : undefined
            : undefined,
        endTime:
          sameHours && restaurantDaysLookup[1]
            ? restaurantDaysLookup[1].shifts[0]
              ? restaurantDaysLookup[1].shifts[0].endTime
              : undefined
            : undefined,
        days: sameHours ? prefilledDays : emptySameHoursDays,
      },
      daily_hours: {
        days: sameHours ? emptyDailyHoursDays : prefilledDays,
      },
    };
  }, [store.days, restaurantDaysLookup, sameHours]);

  return (
    <SidePanel open={open} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        validate={validateMobileHours}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        // validateOnBlur={true}
        subscription={{ submitting: true }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="h-full">
            <SidePanelLayout>
              <SidePanelHeader
                title="Update mobile hours"
                subtitle={store.name}
                onClose={onClose}
              />
              <SidePanelContent>
                <div className="flex justify-between">
                  <div>Same Hours Every Day</div>
                  <div>
                    <Field<boolean>
                      name="same_hours_active"
                      type="checkbox"
                      render={({ input, meta }) => (
                        <FormControl>
                          <Switch input={input} meta={meta} />
                        </FormControl>
                      )}
                    />
                  </div>
                </div>

                <Field
                  name="same_hours_active"
                  type="checkbox"
                  subscription={{ value: true }}
                  render={({ input }) =>
                    input.checked ? (
                      <RestaurantSameHours timeSlots={timeSlotsAsOptions} />
                    ) : (
                      <RestaurantDailyHours timeSlots={timeSlotsAsOptions} />
                    )
                  }
                />
              </SidePanelContent>
              <SidePanelFooter>
                <div className="flex justify-evenly">
                  <Button className="w-full mr-1" onClick={onClose}>
                    Cancel
                  </Button>
                  <PrimaryButton
                    className="w-full ml-1"
                    type="submit"
                    disabled={submitting}
                  >
                    Save
                  </PrimaryButton>
                </div>
              </SidePanelFooter>
            </SidePanelLayout>
          </form>
        )}
      />
    </SidePanel>
  );
}
