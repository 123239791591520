import { useQueryClient } from "@tanstack/react-query";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import ActionModal from "../../components/ActionModal";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import FeatureTitle from "../../components/FeatureTitle";
import { WarningOutlinedIcon } from "../../components/Icons";
import useAddDiscount from "../../hooks/discounts/useAddDiscounts";
import useDeleteDiscount from "../../hooks/discounts/useDeleteDiscount";
import usePartnerDiscounts from "../../hooks/discounts/usePartnerDiscounts";
import DiscountsContent from "./components/DiscountsContent";
import AddDiscountSidePanel from "./components/AddDiscountSidePanel";
import DiscountCard from "./components/DiscountCard";
import DiscountsStart from "./components/DiscountsStart";
import DiscountsTable from "./components/DiscountsTable";
import { PARTNER_DISCOUNTS_QUERY_KEY } from "./constants";
import DiscountsEmptyState from "./components/DiscountsEmptyState";
import { useSearchParams } from "react-router-dom";
import FeatureContentWrapper from "../../components/FeatureContentWrapper";
import { PermissionsContext } from "../../context/permissions/PermissionsContext";
import { DiscountToggle } from "../../components/DiscountToggle/DiscountToggle";
import usePartnerFeatures from "../../hooks/features/usePartnerFeatures";
import { PERMISSIONS_FEATURE_MANAGEMENT } from "../../constants/permissions";
import FeatureHeader from "../../components/FeatureHeader";
import FeatureHeaderSiteDropdown from "../../components/FeatureHeader/FeatureHeaderSiteDropdown";
import { Helmet } from "react-helmet-async";
import useOrgId from "../../hooks/organizations/useOrgId";

export default function Discounts() {
  const [queryParams] = useSearchParams();
  const orgId = useOrgId();

  const siteId = queryParams.get("site") || "";
  const { data: partnerFeatures } = usePartnerFeatures(siteId);

  const { hasPermission } = useContext(PermissionsContext);

  const queryClient = useQueryClient();
  const { data: discountsData } = usePartnerDiscounts(siteId);

  const deleteDiscount = useDeleteDiscount();
  const addDiscount = useAddDiscount();

  const [discountToBeDeleted, setDiscountToBeDeleted] = useState<string | null>(
    null
  );

  const [showDeleteDiscountModal, setShowDeleteDiscountModal] = useState(false);

  const [showAddDiscountPanel, setShowAddDiscountPanel] = useState(false);

  const onPromptDeleteDiscount = (discountCode: string) => {
    setDiscountToBeDeleted(discountCode);
    setShowDeleteDiscountModal(true);
  };

  const onDeleteDiscount = () => {
    discountToBeDeleted &&
      deleteDiscount.mutate(
        {
          partnerId: siteId,
          discountCode: discountToBeDeleted,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([PARTNER_DISCOUNTS_QUERY_KEY, orgId]);

            onHideDeleteDiscountModal();
            setDiscountToBeDeleted(null);

            toast.success("Discount deleted successfully.");
          },
          onError: () => {
            onHideDeleteDiscountModal();
            setDiscountToBeDeleted(null);
            toast.error("Failed to delete Discount. Please try again.");
          },
        }
      );
  };

  const onHideDeleteDiscountModal = () => {
    setShowDeleteDiscountModal(false);
  };

  const onShowAddDiscountPanel = () => setShowAddDiscountPanel(true);
  const onHideAddDiscountPanel = () => setShowAddDiscountPanel(false);

  const onAddDiscount = async (values: {
    productId: number;
    discountCode: string;
    percentage: number;
  }) => {
    return addDiscount
      .mutateAsync({
        partnerId: siteId,
        ...values,
      })
      .then(() => {
        queryClient.invalidateQueries([PARTNER_DISCOUNTS_QUERY_KEY, orgId]);
        onHideAddDiscountPanel();
        toast.success("Discount added successfully.");
      })
      .catch(() => {
        return {
          discountCode:
            "The discount code already exists. Please try a new one.",
        };
      });
  };

  if (siteId === "") {
    return (
      <FeatureContentWrapper>
        <Helmet>
          <title>Connect - Discounts</title>
        </Helmet>
        <div className="border-b border-gray-300 mb-7">
          <FeatureHeader>
            <FeatureHeaderSiteDropdown />
          </FeatureHeader>
        </div>
        <DiscountsStart />
      </FeatureContentWrapper>
    );
  }

  return (
    <FeatureContentWrapper>
      <Helmet>
        <title>Connect - Discounts</title>
      </Helmet>
      <div className="border-b border-gray-300 mb-7">
        <FeatureHeader>
          <FeatureHeaderSiteDropdown />
        </FeatureHeader>
      </div>
      <DiscountsContent className="flex flex-col">
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <FeatureTitle className="mb-7 grow">Discounts</FeatureTitle>
          {hasPermission(PERMISSIONS_FEATURE_MANAGEMENT.DISCOUNT_TOGGLE) && (
            <div>
              <div className="bg-gray-50 rounded-lg px-5 py-3 lg:mr-4 lg:mb-0 mb-4 flex flex-row">
                <div className="mr-5 text-sm mt-0.5 grow font-semibold">
                  Enable discounts
                </div>
                <div>
                  {partnerFeatures && (
                    <DiscountToggle
                      orgId={orgId}
                      partnerId={siteId}
                      enabled={partnerFeatures.hasDiscount}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {hasPermission(PERMISSIONS_FEATURE_MANAGEMENT.DISCOUNT_ADD) && (
            <PrimaryButton
              type="button"
              onClick={onShowAddDiscountPanel}
              className="mb-5"
            >
              Add Discount
            </PrimaryButton>
          )}
        </div>
        {discountsData && discountsData.length === 0 ? (
          <DiscountsEmptyState />
        ) : null}

        <div className="flex flex-col lg:hidden">
          {discountsData && discountsData.length > 0
            ? discountsData.map((discount) => (
                <DiscountCard
                  key={discount.discountCode}
                  code={discount.discountCode}
                  discount={`${discount.percentage}%`}
                  onDeleteDiscount={onPromptDeleteDiscount}
                />
              ))
            : null}
        </div>

        <div className="hidden lg:flex">
          {discountsData && discountsData.length > 0 ? (
            <DiscountsTable
              data={discountsData}
              onDeleteDiscount={onPromptDeleteDiscount}
            />
          ) : null}
        </div>

        {discountToBeDeleted && showDeleteDiscountModal ? (
          <ActionModal
            title="Delete Discount?"
            icon={<WarningOutlinedIcon className="w-10 h-10" />}
            content={`Are you sure you want to delete the ${discountToBeDeleted} discount?`}
            footer={
              <div>
                <SecondaryButton
                  onClick={onHideDeleteDiscountModal}
                  className="py-1.5 px-4 mr-2"
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  onClick={onDeleteDiscount}
                  className="py-1.5 px-4"
                >
                  Yes, Delete
                </PrimaryButton>
              </div>
            }
            onClose={onHideDeleteDiscountModal}
          />
        ) : null}

        <AddDiscountSidePanel
          open={showAddDiscountPanel}
          onAddDiscount={onAddDiscount}
          onClose={onHideAddDiscountPanel}
        />
      </DiscountsContent>
    </FeatureContentWrapper>
  );
}
