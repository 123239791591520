import { useCallback, useState } from "react";
import { Field, useField, useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import { DropdownOptionType } from "../../../../components/Dropdown/DropdownOption";
import FormControl from "../../../../components/Forms/FormControl";
import { ChevronDownIcon } from "../../../../components/Icons";
import Switch from "../../../../components/Switch/Switch";
import DayShiftsStatus from "../DayShiftsStatus";

interface DailyHoursConfiguratorProps {
  fieldName: string;
  timeSlots: DropdownOptionType[];
}

export default function DailyHoursConfigurator({
  fieldName,
  timeSlots,
}: DailyHoursConfiguratorProps) {
  const [open, setOpen] = useState(false);

  const onToggleOpen = () => {
    setOpen(!open);
  };

  const { input: allowOrdersField } = useField(`${fieldName}.active`);
  const { input: shiftsField, meta: shiftsFieldMeta } = useField(
    `${fieldName}.shifts`,
    { validateFields: [] }
  );

  const form = useForm();

  const { input: dayNameField } = useField(`${fieldName}.dayName`);

  const shiftsFieldHasError =
    shiftsFieldMeta.touched && Array.isArray(shiftsFieldMeta.error)
      ? !!shiftsFieldMeta.error.filter(Boolean).length
      : false;

  const filterTimeFromSlots = useCallback(
    (
      currentFieldIndex: number,
      timeToValue: string | undefined,
      fields: { value: { endTime: string }[] }
    ) => {
      return timeSlots.filter((slot) => {
        if (currentFieldIndex === 0) {
          return timeToValue !== undefined && timeToValue !== ""
            ? slot.value < timeToValue
            : true;
        }
        return timeToValue !== undefined && timeToValue !== ""
          ? slot.value > fields.value[currentFieldIndex - 1].endTime &&
          slot.value < timeToValue
          : slot.value > fields.value[currentFieldIndex - 1].endTime;
      });
    },
    [timeSlots]
  );

  const filterTimeToSlots = useCallback(
    (
      currentFieldIndex: number,
      timeFromValue: string | undefined,
      fields: { value: { endTime: string }[] }
    ) => {
      return timeSlots.filter((slot) => {
        if (currentFieldIndex === 0) {
          return timeFromValue !== undefined && timeFromValue !== ""
            ? slot.value > timeFromValue
            : true;
        }
        return timeFromValue !== undefined && timeFromValue !== ""
          ? slot.value > timeFromValue &&
          slot.value > fields.value[currentFieldIndex - 1].endTime
          : slot.value > fields.value[currentFieldIndex - 1].endTime;
      });
    },
    [timeSlots]
  );

  return (
    <div
      className={`flex flex-col border rounded-lg px-4 py-3 mb-3 last:mb-0 select-none ${open
          ? "border-gray-300"
          : "bg-gray-100 border-gray-100 h-12 overflow-hidden "
        } ${shiftsFieldHasError && "!border-error-500"}`}
    >
      <div className={`flex ${open ? "mb-4" : "mb-2"}`}>
        <div className="flex-grow cursor-pointer" onClick={onToggleOpen}>
          <span className="font-bold tracking-wide">{dayNameField.value}</span>
        </div>
        <div className="flex flex-grow-0">
          {open ? (
            <Field<boolean>
              name={`${fieldName}.active`}
              type="checkbox"
              validateFields={[]}
              render={({ input, meta }) => (
                <Switch
                  input={{
                    ...input,
                    onChange: (e) => {
                      input.onChange(e);
                      input.onBlur();
                    },
                  }}
                  meta={meta}
                  label={input.checked ? "Open" : "Closed"}
                  labelPosition="left"
                  className="!mb-0"
                />
              )}
            />
          ) : (
            <div onClick={onToggleOpen} className="cursor-pointer">
              <DayShiftsStatus
                disabled={!allowOrdersField.value}
                shifts={shiftsField.value}
                className={shiftsFieldHasError ? "text-error-500" : ""}
              />
            </div>
          )}

          <button type="button" onClick={onToggleOpen} className="ml-2">
            {" "}
            <ChevronDownIcon
              className={`h-5 w-5 text-gray-900 transition-transform  ${open && "rotate-180"
                }`}
            />
          </button>
        </div>
      </div>

      <FieldArray
        name={`${fieldName}.shifts`}
        subscription={{ value: true }}
        render={({ fields }) =>
          fields.map((name, index) => (
            <div key={name} className="border-b last:border-b-0 mb-4 last:mb-0">
              {fields.value.length > 1 ? (
                <div className="flex justify-between items-center">
                  <span className="text-sm font-bold mb-2">
                    Shift {index + 1}{" "}
                    {index === 2 ? (
                      <span className="text-gray-400 font-normal">
                        (Optional)
                      </span>
                    ) : null}
                  </span>
                  {index === 2 ? (
                    <button
                      onClick={() => fields.remove(2)}
                      type="button"
                      className="text-sm underline disabled:opacity-30"
                      aria-label={`Remove third shift for ${dayNameField.value}`}
                      disabled={!allowOrdersField.value}
                    >
                      Remove
                    </button>
                  ) : null}
                </div>
              ) : null}
              <div className="flex justify-between">
                <div className="w-[45%]">
                  <Field
                    name={`${name}.endTime`}
                    subscription={{ value: true }}
                    validateFields={[]}
                    render={({ input: endInput }) => (
                      <Field
                        name={`${name}.startTime`}
                        validateFields={[]}
                        render={({ input, meta }) => (
                          <FormControl>
                            <Dropdown
                              label="From"
                              input={input}
                              meta={meta}
                              options={filterTimeFromSlots(
                                index,
                                endInput.value,
                                fields
                              )}
                              placeholder="--:--"
                              disabled={!allowOrdersField.value}
                            />
                          </FormControl>
                        )}
                      />
                    )}
                  />
                </div>
                <div className="w-[10%] flex px-4 justify-center items-center">
                  <span className="px-2 h-0.5 block bg-black" />
                </div>
                <div className="w-[45%]">
                  <Field
                    name={`${name}.startTime`}
                    validateFields={[]}
                    subscription={{ value: true }}
                    render={({ input: startInput }) => (
                      <Field
                        name={`${name}.endTime`}
                        validateFields={[]}
                        render={({ input, meta }) => (
                          <FormControl>
                            <Dropdown
                              label="To"
                              input={{
                                ...input,
                                onChange: (val) => {
                                  input.onChange(val);

                                  // if the next shifts have set times that are lower that the current shift end time
                                  // then we need to reset them
                                  if (
                                    fields.value.length > 1 &&
                                    fields.value.length > index + 1
                                  ) {
                                    for (
                                      let nextField = index + 1;
                                      nextField < fields.value.length;
                                      nextField++
                                    ) {
                                      fields.value[nextField].startTime < val &&
                                        fields.update(nextField, {
                                          startTime: "",
                                          endTime: "",
                                        });
                                    }
                                  }
                                },
                              }}
                              meta={meta}
                              options={filterTimeToSlots(
                                index,
                                startInput.value,
                                fields
                              )}
                              placeholder="--:--"
                              disabled={!allowOrdersField.value}
                            />
                          </FormControl>
                        )}
                      />
                    )}
                  />
                </div>
              </div>
              {fields.value.length === 1 ? (
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="text-sm underline disabled:opacity-30"
                    aria-label={`Setup Split Shift for ${dayNameField.value}`}
                    disabled={!allowOrdersField.value}
                    onClick={() => {
                      fields.push({
                        startTime: "",
                        endTime: "",
                      });

                      form.resetFieldState(`${fieldName}.shifts`);
                    }}
                  >
                    Setup Split Shift
                  </button>
                </div>
              ) : null}

              <div>
                {index === 1 && fields.value.length === 2 ? (
                  <div>
                    <div className="flex justify-start">
                      <button
                        type="button"
                        className="text-sm underline disabled:opacity-30"
                        aria-label={`Add Third Shift for ${dayNameField.value}`}
                        disabled={
                          fields.value[1].startTime === "" ||
                          fields.value[1].endTime === "" ||
                          !allowOrdersField.value
                        }
                        onClick={() => {
                          fields.push({
                            startTime: "",
                            endTime: "",
                          });
                          form.resetFieldState(`${fieldName}.shifts`);
                        }}
                      >
                        + Add Third Shift
                      </button>
                    </div>
                    <div className="flex justify-end">
                      <button
                        onClick={() => {
                          fields.remove(1);
                        }}
                        type="button"
                        className="text-sm underline disabled:opacity-30"
                        aria-label={`Setup Single Shift for ${dayNameField.value}`}
                        disabled={!allowOrdersField.value}
                      >
                        Setup Single Shift
                      </button>
                    </div>
                  </div>
                ) : null}
                {index === 2 && fields.value.length >= 2 ? (
                  <div className="flex justify-end">
                    <button
                      onClick={() => {
                        fields.remove(2);
                        fields.remove(1);
                      }}
                      type="button"
                      className="text-sm underline disabled:opacity-30"
                      aria-label={`Setup Single Shift for ${dayNameField.value}`}
                      disabled={!allowOrdersField.value}
                    >
                      Setup Single Shift
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          ))
        }
      />
    </div>
  );
}
