import { FieldArray } from "react-final-form-arrays";
import { DropdownOptionType } from "../../../../components/Dropdown/DropdownOption";
import OrderThrottlingDailyHoursConfigurator from "./OrderThrottlingDailyHoursConfigurator";

interface OrderThrottlingDailyHoursProps {
  timeSlots: DropdownOptionType[];
}

const OrderThrottlingDailyHours = ({
  timeSlots,
}: OrderThrottlingDailyHoursProps) => {
  return (
    <FieldArray
      name="daily_hours.days"
      subscription={{}}
      render={({ fields }) =>
        fields.map((name) => (
          <OrderThrottlingDailyHoursConfigurator
            key={name}
            fieldName={name}
            timeSlots={timeSlots}
          />
        ))
      }
    />
  );
};

export default OrderThrottlingDailyHours;
