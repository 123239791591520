import { useMutation } from "@tanstack/react-query";
import { CapacityScheduleInterface } from "../../features/Stores/types";
import { updateRestaurantCapacitySchedule } from "../../services/restaurants";

export default function useUpdateCapacitySchedule() {
  return useMutation(
    ({
      restaurantId,
      schedule,
    }: {
      restaurantId: string;
      schedule: CapacityScheduleInterface[];
    }) => updateRestaurantCapacitySchedule(restaurantId, schedule)
  );
}
