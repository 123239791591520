import { FieldArray } from "react-final-form-arrays";
import { DropdownOptionType } from "../../../../components/Dropdown/DropdownOption";
import DailyHoursConfigurator from "./DailyHoursConfigurator";

interface RestaurantDailyHoursProps {
  timeSlots: DropdownOptionType[];
}
export default function RestaurantDailyHours({
  timeSlots,
}: RestaurantDailyHoursProps) {
  return (
    <div>
      <FieldArray
        name="daily_hours.days"
        subscription={{}}
        render={({ fields }) =>
          fields.map((name) => (
            <DailyHoursConfigurator
              key={name}
              fieldName={name}
              timeSlots={timeSlots}
            />
          ))
        }
      />
    </div>
  );
}
