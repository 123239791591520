import { useSearchParams } from "react-router-dom";
import FeatureContentWrapper from "../../components/FeatureContentWrapper";
import FeatureTitle from "../../components/FeatureTitle";
import usePartnerFeatures from "../../hooks/features/usePartnerFeatures";
import PartnerFeatureDiscounts from "./components/PartnerFeatureDiscounts";
import PartnerFeatureRoundUp from "./components/PartnerFeatureRoundUp";
import PartnerFeaturesStart from "./components/PartnerFeaturesStart";
import PartnerFeaturesContent from "./components/PartnerFeaturesContent";
import FeatureHeaderSiteDropdown from "../../components/FeatureHeader/FeatureHeaderSiteDropdown";
import FeatureHeader from "../../components/FeatureHeader";
import { Helmet } from "react-helmet-async";
import useOrgId from "../../hooks/organizations/useOrgId";

export default function FeatureManagement() {
  const [queryParams] = useSearchParams();
  const orgId = useOrgId();

  const siteId = queryParams.get("site") || "";

  const { data: partnerFeatures } = usePartnerFeatures(siteId);

  if (siteId === "") {
    return (
      <FeatureContentWrapper>
        <Helmet>
          <title>Connect - Feature management</title>
        </Helmet>
        <div className="border-b border-gray-300 mb-7">
          <FeatureHeader>
            <FeatureHeaderSiteDropdown />
          </FeatureHeader>
        </div>
        <PartnerFeaturesStart />
      </FeatureContentWrapper>
    );
  }

  return (
    <FeatureContentWrapper>
      <Helmet>
        <title>Connect - Feature management</title>
      </Helmet>
      <div className="border-b border-gray-300 mb-7">
        <FeatureHeader>
          <FeatureHeaderSiteDropdown />
        </FeatureHeader>
      </div>

      {partnerFeatures && (
        <PartnerFeaturesContent>
          <FeatureTitle className="mb-7">Feature Management</FeatureTitle>
          <div className="space-y-4">
            <PartnerFeatureDiscounts
              orgId={orgId}
              partnerId={siteId}
              enabled={partnerFeatures.hasDiscount}
              needsSetup={false}
            />

            <PartnerFeatureRoundUp
              orgId={orgId}
              partnerId={siteId}
              enabled={partnerFeatures.hasRoundUp}
              needsSetup={!partnerFeatures.hasRoundUpBeenSetup}
              roundUp={partnerFeatures.roundUp}
            />
          </div>
        </PartnerFeaturesContent>
      )}
    </FeatureContentWrapper>
  );
}
