import { useMemo } from "react";
import { DAYS_OF_WEEK_MAP } from "../../constants";
import { CapacityScheduleInterface, StoreInterface } from "../../types";
import OrderThrottlingStatus from "../OrderThrottlingStatus";

interface OrderThrottlingTableProps {
  schedule: CapacityScheduleInterface[];
  store: StoreInterface;
}

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const OrderThrottlingTable = ({ schedule }: OrderThrottlingTableProps) => {
  const result = Array.from(schedule);
  const missing = days.filter(
    (day) => !schedule.find((scheDay) => scheDay.day === day)
  );

  missing.forEach((miss) =>
    result.push({ day: miss, capacity: null, startTime: null, endTime: null })
  );

  const daysLookup: Record<number, any> = useMemo(() => {
    return result.reduce((next, item, index) => {
      return { ...next, [index]: item };
    }, {});
  }, [result]);

  return (
    <div data-testid="throttling-table">
      {schedule ? (
        <div className="flex flex-col">
          {DAYS_OF_WEEK_MAP.map((day) => (
            <OrderThrottlingStatus
              key={day.value}
              day={daysLookup[day.value].day}
              capacity={daysLookup[day.value].capacity}
              start={daysLookup[day.value].startTime}
              end={daysLookup[day.value].endTime}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default OrderThrottlingTable;
