import { useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import InputField from "../../../../components/Forms/InputField";
import TextArea from "../../../../components/Forms/TextArea";
import Switch from "../../../../components/Switch/Switch";
import Typography from "../../../../components/Typography";
import { PartnerFeatureRoundUpInterface } from "../../../FeatureManagement/types";
import * as yup from "yup";
import { useValidationSchema } from "../../../../hooks/validations/useValidationSchema";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import ActionModal from "../../../../components/ActionModal";
import { FormApi } from "final-form";
import { PERMISSIONS_FEATURE_MANAGEMENT } from "../../../../constants/permissions";
import { usePermissions } from "../../../../hooks/permissions/usePermissions";
import FormControl from "../../../../components/Forms/FormControl";

interface RoundUpsFormProps {
  roundUp: PartnerFeatureRoundUpInterface | null;
  isActive: boolean;
  onSubmit: (values: {
    roundUp: PartnerFeatureRoundUpInterface;
    isActive: boolean;
  }) => void;
}

const DESCRIPTION_MAX_CHARACTERS = 300;

const validateRoundUp = yup.object({
  isActive: yup.boolean(),
  roundUp: yup.object({
    title: yup.string().required("Please enter Title (English)."),
    titleSpanish: yup.string().required("Please enter Title (Spanish)."),
    description: yup
      .string()
      .required("Please enter Description (English).")
      .max(
        DESCRIPTION_MAX_CHARACTERS,
        `Description (English) can be a maximum of ${DESCRIPTION_MAX_CHARACTERS} charaters.`
      ),
    descriptionSpanish: yup
      .string()
      .required("Please enter Description (Spanish).")
      .max(
        DESCRIPTION_MAX_CHARACTERS,
        `Description (Spanish) can be a maximum of ${DESCRIPTION_MAX_CHARACTERS} characters.`
      ),
  }),
});

export default function RoundUpsForm({
  roundUp,
  isActive,
  onSubmit,
}: RoundUpsFormProps) {
  const { hasPermission } = usePermissions();
  const canUpdateRoundUps = hasPermission(
    PERMISSIONS_FEATURE_MANAGEMENT.ROUNDUP_UPDATE
  );
  const canToggleRoundUps = hasPermission(
    PERMISSIONS_FEATURE_MANAGEMENT.ROUNDUP_TOGGLE
  );

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onHideConfirmModal = () => setShowConfirmModal(false);

  const onActivateRoundUps = (
    form: FormApi<
      { roundUp: PartnerFeatureRoundUpInterface; isActive: boolean },
      Partial<{ roundUp: PartnerFeatureRoundUpInterface; isActive: boolean }>
    >
  ) => {
    form.change("isActive", true);
    onHideConfirmModal();
  };

  const initialValues = useMemo(
    () => ({
      roundUp: {
        title: roundUp ? roundUp.title : undefined,
        titleSpanish: roundUp ? roundUp.titleSpanish : undefined,
        description: roundUp ? roundUp.description : undefined,
        descriptionSpanish: roundUp ? roundUp.descriptionSpanish : undefined,
      },
      isActive,
    }),
    [roundUp, isActive]
  );

  const validate = useValidationSchema(validateRoundUp);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      subscription={{ submitting: true }}
      render={({ handleSubmit, submitting, form }) => (
        <form onSubmit={handleSubmit}>
          <div className="border-b border-b-gray-300 pb-7">
            <div className="flex flex-col lg:flex-row justify-between">
              <div className="w-full pr-0 lg:pr-[4.375rem]">
                <Field
                  name="roundUp.title"
                  render={({ input, meta }) => (
                    <FormControl>
                      <InputField
                        label="Title (English)"
                        input={input}
                        meta={meta}
                        readOnly={!canUpdateRoundUps}
                      />
                    </FormControl>
                  )}
                />
              </div>
              <div className="w-full pl-0 lg:pl-[4.375rem]">
                <Field
                  name="roundUp.titleSpanish"
                  render={({ input, meta }) => (
                    <FormControl>
                      <InputField
                        label="Title (Spanish)"
                        input={input}
                        meta={meta}
                        readOnly={!canUpdateRoundUps}
                      />
                    </FormControl>
                  )}
                />
              </div>
            </div>

            <div className="flex flex-col lg:flex-row justify-between">
              <div className="w-full pr-0 lg:pr-[4.375rem]">
                <Field
                  name="roundUp.description"
                  render={({ input, meta }) => (
                    <FormControl>
                      <TextArea
                        label="Description (English)"
                        input={input}
                        meta={meta}
                        maxLength={DESCRIPTION_MAX_CHARACTERS}
                        rows={3}
                        helperText={`Maximum ${DESCRIPTION_MAX_CHARACTERS} characters`}
                        readOnly={!canUpdateRoundUps}
                      />
                    </FormControl>
                  )}
                />
              </div>

              <div className="w-full pl-0 lg:pl-[4.375rem]">
                <Field
                  name="roundUp.descriptionSpanish"
                  render={({ input, meta }) => (
                    <FormControl>
                      <TextArea
                        label="Description (Spanish)"
                        input={input}
                        meta={meta}
                        maxLength={DESCRIPTION_MAX_CHARACTERS}
                        rows={3}
                        helperText={`Maximum ${DESCRIPTION_MAX_CHARACTERS} characters`}
                        readOnly={!canUpdateRoundUps}
                      />
                    </FormControl>
                  )}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row justify-between">
            <div className="w-full pr-0 lg:pr-[4.375rem] pt-6">
              <div className="bg-gray-50 rounded-lg p-4 flex justify-between">
                <div>
                  <Typography as="body-2" className="font-semibold">
                    Make roundups visible to guests?
                  </Typography>
                  <Typography as="caption" className="text-gray-500">
                    You can also manage this in Feature Management.
                  </Typography>
                </div>
                <div>
                  <Field
                    name="isActive"
                    type="checkbox"
                    render={({ input, meta }) => (
                      <FormControl>
                        <Switch
                          label={input.checked ? "On" : "Off"}
                          labelPosition="left"
                          input={{
                            ...input,
                            onChange: (e) => {
                              if (e.target.checked === true) {
                                setShowConfirmModal(true);
                                input.onChange(false);
                              } else {
                                input.onChange(e);
                              }
                            },
                          }}
                          meta={meta}
                          readOnly={!canUpdateRoundUps || !canToggleRoundUps}
                        />
                      </FormControl>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="w-full pl-0 lg:pl-[4.375rem] pt-6 flex justify-end">
              <PrimaryButton
                type="submit"
                disabled={submitting || !canUpdateRoundUps}
                className="py-1.5 px-4"
              >
                Save
              </PrimaryButton>
            </div>
          </div>

          {showConfirmModal ? (
            <ActionModal
              title="Are you sure you want to enable Round Ups in the digital experience?"
              content="You can also do this later in Feature Management."
              onClose={onHideConfirmModal}
              footer={
                <div>
                  <SecondaryButton
                    className="py-1.5 px-4 mr-2"
                    onClick={onHideConfirmModal}
                  >
                    Not now
                  </SecondaryButton>
                  <PrimaryButton
                    className="py-1.5 px-4"
                    disabled={submitting}
                    onClick={() => onActivateRoundUps(form)}
                  >
                    Yes, turn on
                  </PrimaryButton>
                </div>
              }
            />
          ) : null}
        </form>
      )}
    />
  );
}
