import Box from "../../../../components/Box";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import Typography from "../../../../components/Typography";
import { PERMISSIONS_FEATURE_MANAGEMENT } from "../../../../constants/permissions";
import { usePermissions } from "../../../../hooks/permissions/usePermissions";

interface DiscountCardProps {
  code: string;
  discount: string;
  onDeleteDiscount: (discountCode: string) => void;
}

export default function DiscountCard({
  code,
  discount,
  onDeleteDiscount,
}: DiscountCardProps) {
  const { hasPermission } = usePermissions();
  return (
    <Box arial-label="discount card">
      <div className="flex flex-col">
        <div className="grid grid-cols-2 gap-8 max-w-[325px] mb-4">
          <div className="flex flex-col">
            <span className="text-xs text-gray-500 leaiding-[0.025rem] mb-1">
              Discount Code
            </span>
            <Typography as="body-2">{code}</Typography>
          </div>
          <div className="flex flex-col">
            <span className="text-xs text-gray-500 leaiding-[0.025rem] mb-1">
              Total Discount
            </span>
            <Typography as="body-2">{discount}</Typography>
          </div>
        </div>
        {hasPermission(PERMISSIONS_FEATURE_MANAGEMENT.DISCOUNT_DELETE) && (
          <div>
            <PrimaryButton
              onClick={() => onDeleteDiscount(code)}
              className="inline py-1.5 px-4"
            >
              Delete
            </PrimaryButton>
          </div>
        )}
      </div>
    </Box>
  );
}
