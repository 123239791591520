import { Field, useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import { DropdownOptionType } from "../../../../components/Dropdown/DropdownOption";
import FormControl from "../../../../components/Forms/FormControl";
import Typography from "../../../../components/Typography";

interface OrderThrottlingSameHoursProps {
  timeSlots: DropdownOptionType[];
  emptyHoursDays: {
    active?: boolean;
    startTime: null;
    endTime: null;
    capacity: null;
    day: string;
    dayName: string;
  }[];
}

const OrderThrottlingSameHours = ({
  timeSlots,
  emptyHoursDays,
}: OrderThrottlingSameHoursProps) => {
  const form = useForm();
  return (
    <div className="flex flex-col border border-gray-300 rounded-lg px-4 py-3">
      <div className="flex justify-between ">
        <span className="font-bold tracking-wide">Daily Throttle</span>
        <Field
          name="same_hours.clear"
          render={() => (
            <div
              role="button"
              className="text-sm underline"
              onClick={() =>
                form.batch(() => {
                  form.change("same_hours.startTime", null);
                  form.change("same_hours.endTime", null);
                  form.change("same_hours.capacity", null);
                  form.change("same_hours.days", emptyHoursDays);
                })
              }
            >
              Clear All
            </div>
          )}
        />
      </div>

      <Field
        name="same_hours.active"
        subscription={{ value: true }}
        render={() => (
          <>
            <div>
              <div className="flex justify-between">
                <div className="w-[45%]">
                  <Field
                    name="same_hours.endTime"
                    subscription={{ value: true }}
                    render={({ input: endInput }) => (
                      <Field
                        name="same_hours.startTime"
                        render={({ input, meta }) => (
                          <FormControl>
                            <Dropdown
                              label="From"
                              input={{
                                ...input,
                                onChange: (val) => {
                                  input.onChange(val);
                                  endInput.onBlur();
                                },
                              }}
                              meta={meta}
                              options={timeSlots.filter((slot) =>
                                endInput.value !== undefined &&
                                endInput.value !== ""
                                  ? slot.value < endInput.value
                                  : true
                              )}
                              placeholder="--:--"
                            />
                          </FormControl>
                        )}
                      />
                    )}
                  />
                </div>
                <div className="w-[10%] flex px-4 justify-center items-center">
                  <span className="px-2 h-0.5 block bg-black" />
                </div>
                <div className="w-[45%]">
                  <Field
                    name="same_hours.startTime"
                    subscription={{ value: true }}
                    render={({ input: startInput }) => (
                      <Field
                        name="same_hours.endTime"
                        render={({ input, meta }) => (
                          <FormControl>
                            <Dropdown
                              label="To"
                              input={{
                                ...input,
                                onChange: (val) => {
                                  input.onChange(val);
                                  startInput.onBlur();
                                },
                              }}
                              meta={meta}
                              options={timeSlots.filter((slot) =>
                                startInput.value !== undefined &&
                                startInput.value !== ""
                                  ? slot.value > startInput.value
                                  : true
                              )}
                              placeholder="--:--"
                            />
                          </FormControl>
                        )}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="">
                <Field
                  name="days_error"
                  subscription={{ error: true, touched: true }}
                  render={({ meta: daysErrorMeta }) => (
                    <div>
                      <div className="flex justify-between">
                        <FieldArray
                          name="same_hours.days"
                          render={({ fields }) =>
                            fields.map((name, index) => (
                              <div key={name}>
                                <Field
                                  key={name}
                                  name={`${name}.active`}
                                  type="checkbox"
                                  render={({ input }) => (
                                    <span>
                                      <input
                                        {...input}
                                        id={`${name}-active`}
                                        aria-label={`${name}-active`}
                                        className="sr-only peer"
                                      />
                                      <label
                                        htmlFor={`${name}-active`}
                                        className={`flex justify-center items-center text-xs  border rounded-full w-8 h-8 cursor-pointer 
                            peer-disabled:bg-gray-100 peer-disabled:text-gray-300 
                            peer-checked:bg-cinchio-blue-500 peer-checked:text-white
                            peer-checked:peer-disabled:bg-cinchio-blue-100 peer-checked:peer-disabled:text-white ${
                              daysErrorMeta.touched && !!daysErrorMeta.error
                                ? "border-error-500"
                                : ""
                            }`}
                                      >
                                        {fields.value[index].dayShortName}
                                      </label>
                                    </span>
                                  )}
                                />

                                <Field
                                  name="same_hours.startTime"
                                  subscription={{ value: true }}
                                  render={({ input: startInput }) => (
                                    <Field
                                      name={`${name}.startTime`}
                                      initialValue={startInput.value}
                                      render={() => null}
                                    />
                                  )}
                                />

                                <Field
                                  name="same_hours.endTime"
                                  subscription={{ value: true }}
                                  render={({ input: endInput }) => (
                                    <Field
                                      name={`${name}.endTime`}
                                      initialValue={endInput.value}
                                      render={() => null}
                                    />
                                  )}
                                />
                                <Field
                                  name="same_hours.capacity"
                                  subscription={{ value: true }}
                                  render={({ input: capacityInput }) => (
                                    <Field
                                      name={`${name}.capacity`}
                                      initialValue={Number(capacityInput.value)}
                                      render={() => null}
                                    />
                                  )}
                                />
                              </div>
                            ))
                          }
                        />
                      </div>
                      {daysErrorMeta.touched && !!daysErrorMeta.error ? (
                        <p className="text-xs text-red-500 h-6 mt-2">
                          {daysErrorMeta.error}
                        </p>
                      ) : null}
                    </div>
                  )}
                />
              </div>
            </div>
            <Field
              name="same_hours.capacity"
              subscription={{ value: true, error: true, touched: true }}
              render={({ input: capacityInput, meta: capacityMeta }) => (
                <>
                  <div className="mt-8 mb-4">
                    <Typography as="subtitle-2" className="font-bold">
                      How many orders will be accepted?
                    </Typography>
                    <input
                      {...capacityInput}
                      type="text"
                      className={`bg-white border border-gray-300 rounded-lg px-3 py-2.5 mb-0.5 text-left cursor-pointer focus:ring-1 
                                  focus:ring-cinchio-blue-500 focus:outline-0  focus:border-cinchio-blue-500 disabled:bg-gray-100 disabled:text-gray-300 
                                  disabled:border-gray-100 ${
                                    capacityMeta.touched && !!capacityMeta.error
                                      ? "border border-error-500"
                                      : ""
                                  }`}
                    ></input>
                    <label htmlFor="capacity" className="font-bold text-sm">
                      {" "}
                      orders / 15 minute block
                    </label>
                    {capacityMeta.touched && !!capacityMeta.error ? (
                      <Typography className="text-xs text-red-500 h-6">
                        {capacityMeta.error}
                      </Typography>
                    ) : (
                      <Typography as="subtitle-2" className="text-gray-500">
                        5 is minimum, 999 maximum
                      </Typography>
                    )}
                  </div>
                </>
              )}
            />
          </>
        )}
      />
    </div>
  );
};

export default OrderThrottlingSameHours;
