import {
  addMinutes,
  isBefore,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  format,
} from "date-fns";

type TimeSlot = {
  displayValue: string;
  value: string;
};

export default function generateTimeSlots({
  start,
  end,
  interval,
  displayTimeFormat,
  valueTimeFormat,
}: {
  start: number;
  end: number;
  interval: number;
  displayTimeFormat: string;
  valueTimeFormat: string;
}) {
  const setTime = (x: Date, h: number): Date =>
    setHours(setMinutes(setSeconds(setMilliseconds(x, 0), 0), 0), h);

  const from = setTime(new Date(), start);
  let to = setTime(new Date(), end);

  const step = (x: Date): Date => addMinutes(x, interval);

  const blocks: TimeSlot[] = [];

  let cursor = from;

  while (isBefore(cursor, to)) {
    blocks.push({
      displayValue: format(cursor, displayTimeFormat),
      value: format(cursor, valueTimeFormat),
    });
    cursor = step(cursor);
  }

  return blocks;
}
