export const PARTNER_RESTAURANTS_QUERY_KEY = "partner-restaurants";

export const STORE_QUERY_KEY = "store";

export const RESTAURANT_CAPACITY_QUERY_KEY = "capacity";

export const RESTAURANT_PRODUCT_SUSPENSIONS_QUERY_KEY = "product-suspensions";

export const RESTAURANT_ORDER_DETAILS_KEY = "restaurant-order-details";

export const DAYS_OF_WEEK_MAP = [
  {
    value: 1,
    name: "Monday",
    shortName: "Mo",
  },
  {
    value: 2,
    name: "Tuesday",
    shortName: "Tu",
  },
  {
    value: 3,
    name: "Wednesday",
    shortName: "We",
  },
  {
    value: 4,
    name: "Thursday",
    shortName: "Th",
  },
  {
    value: 5,
    name: "Friday",
    shortName: "Fr",
  },
  {
    value: 6,
    name: "Saturday",
    shortName: "Sa",
  },
  {
    value: 0,
    name: "Sunday",
    shortName: "Su",
  },
];
