import { Helmet } from "react-helmet-async";
import FeatureTitle from "../../../components/FeatureTitle";
import FeatureWidgetGrid from "../../../components/FeatureWidgetGrid";
import {
  PERMISSIONS_MENU_MANAGEMENT,
  PERMISSIONS_OPS_MANAGEMENT,
} from "../../../constants/permissions";
import useRestaurantsOutletContext from "../../../context/restaurants/useRestaurantsOutletContext";
import useOrgId from "../../../hooks/organizations/useOrgId";
import { usePermissions } from "../../../hooks/permissions/usePermissions";
import AdvancedOrderingWidget from "../components/AdvancedOrderingWidget";
import ManageMobileOrderHoursWidget from "../components/ManageMobileOrderHoursWidget";
import OnsiteOrderingWidget from "../components/OnsiteOrderingWidget";
import OrderThrottlingWidget from "../components/OrderThrottlingWidget";
import QrCodeGenerationWidget from "../components/QrCodeGenerationWidget";
import RestaurantContent from "../components/RestaurantContent";
import RestaurantsStart from "../components/RestaurantsStart";
import SpecialInstructionsWidget from "../components/SpecialInstructionsWidget";
import OrderAvailabilityWidget from "./components/OrderAvailabilityWidget";

export default function OrderManagement() {
  const { site, store } = useRestaurantsOutletContext();
  const orgId = useOrgId();

  const { hasPermission } = usePermissions();

  if (site === "" || store === "") {
    return <RestaurantsStart />;
  }

  return (
    <RestaurantContent>
      <Helmet>
        <title>Connect - Sites - Order management</title>
      </Helmet>
      <FeatureTitle className="mb-7">Order management</FeatureTitle>
      <FeatureWidgetGrid>
        <OrderAvailabilityWidget orgId={orgId} storeId={store} />

        {hasPermission(PERMISSIONS_OPS_MANAGEMENT.STORE_HOURS) && (
          <ManageMobileOrderHoursWidget orgId={orgId} storeId={store} />
        )}

        <AdvancedOrderingWidget orgId={orgId} storeId={store} />
        <OrderThrottlingWidget orgId={orgId} storeId={store} />
        {hasPermission(PERMISSIONS_MENU_MANAGEMENT.PRODUCT_SUSPENSION) && (
          <SpecialInstructionsWidget orgId={orgId} storeId={store} />
        )}

        <QrCodeGenerationWidget storeId={store} />
        {hasPermission(PERMISSIONS_OPS_MANAGEMENT.TABLE_MANAGEMENT) && (
          <OnsiteOrderingWidget storeId={store} orgId={orgId} />
        )}
      </FeatureWidgetGrid>
    </RestaurantContent>
  );
}
