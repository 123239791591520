import { useEffect, useMemo, useRef, useState } from "react";
import { CloseIcon } from "../Icons";

export type ToolTipPosition = "left" | "right" | "top" | "bottom";

interface ToolTipProps {
  children: React.ReactNode;
  trigger: React.ReactNode;
  position: ToolTipPosition;
}

const tooltipContainerClasses = {
  top: "tooltip-top -translate-y-full -top-[30px] left-1 -translate-x-1/2",
  bottom: "tooltip-bottom translate-y-0 top-[14px]  left-1 -translate-x-1/2",
  left: "tooltip-left translate-y-0 -top-[32px] -left-4 -translate-x-full",
  right: "tooltip-right translate-y-0 -top-[32px] -right-4 translate-x-full",
};

const tooltipArrowClasses = {
  top: "tooltip-arrow-top before:top-full before:left-[calc(50%-5px)] before:border-t-gray-300  after:top-full after:border-t-white  after:-mt-[1px] after:left-[calc(50%-5px)]",
  bottom:
    "tooltip-arrow-bottom before:-top-[16px] before:left-[calc(50%-5px)] before:border-b-gray-300 after:-top-[15px] after:border-b-white after:left-[calc(50%-5px)] after:-mb-[1px]",
  left: "tooltip-arrow-left before:top-[15px] before:left-full before:border-l-gray-300 after:top-[15px] after:left-full after:border-l-white after:-ml-[1px]",
  right:
    "tooltip-arrow-right before:top-[15px] before:right-full before:border-r-gray-300  after:top-[15px] after:right-full after:border-r-white  after:-mr-[1px]",
};

const ToolTip = ({ children, trigger, position }: ToolTipProps) => {
  const [shown, setShown] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);

  const onShow = () => setShown(true);
  const onHide = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setShown(false);
  };

  const wrapperClass = useMemo(
    () => tooltipContainerClasses[position],
    [position]
  );
  const arrowClass = useMemo(() => tooltipArrowClasses[position], [position]);

  useEffect(() => {
    const onHideOnClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      containerRef.current &&
        !containerRef.current.contains(target) &&
        setShown(false);
    };

    window.addEventListener("click", onHideOnClickOutside);
    return () => window.removeEventListener("click", onHideOnClickOutside);
  });

  return (
    <div ref={containerRef}>
      <button
        className="align-middle"
        type="button"
        ref={triggerRef}
        onClick={onShow}
      >
        {trigger}
      </button>

      {shown ? (
        <div className="relative">
          <div
            className={`
      
        text-sm min-w-max

        absolute z-10
        ${wrapperClass}

        rounded bg-white
        border border-gray-300
        text-black
        transition
        shadow-lg
        flex
        items-start
        justify-between
        
        before:content-['']
        before:absolute
        before:border-transparent
        before:border-[8px]
        before:z-[1]

        after:content-['']
        after:absolute
        after:border-transparent

        after:border-[8px]
        after:z-[2]

        ${arrowClass}
        `}
          >
            <div className="relative pl-3 pr-7 py-3  font-normal">
              {children}
              <button
                ref={closeButtonRef}
                className="absolute right-2 top-2"
                type="button"
                onClick={onHide}
                aria-label="close tooltip"
              >
                <CloseIcon className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ToolTip;
