import { useMutation } from "@tanstack/react-query";
import { deleteDiscount } from "../../services/discounts";

export default function useDeleteDiscount() {
  return useMutation(
    ({
      partnerId,
      discountCode,
    }: {
      partnerId: string;
      discountCode: string;
    }) => deleteDiscount(partnerId, discountCode)
  );
}
