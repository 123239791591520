import PercentIcon from "../../../../components/Icons/PercentIcon";
import DiscountsContent from "../DiscountsContent";

export default function DiscountsStart() {
  return (
    <DiscountsContent className="flex justify-center">
      <div className="flex flex-col items-center w-52 mt-20 md:-mt-20 md:justify-center">
        <PercentIcon className="text-black w-12 h-12 mb-2.5" />
        <p className="text-gray-500 text-center">
          Please select site to start.
        </p>
      </div>
    </DiscountsContent>
  );
}
