import { useMemo } from "react";
import ExternalLink from "../../../../../components/ExternalLink";
import LaunchIcon from "../../../../../components/Icons/LauchIcon";
import { useMenuCurrency } from "../../hooks/useMenuCurrency";
import { MenuItemModifierGroupModifierInterface } from "../../types";
import MenuPreviewItemProperty from "../MenuPreviewItemProperty";

export default function MenuPreviewItemModifier({
  modifier,
}: {
  modifier: MenuItemModifierGroupModifierInterface;
}) {
  const errors: Record<string, string[]> = useMemo(
    () =>
      modifier.errors.reduce((acc: Record<string, string[]>, item) => {
        if (Array.isArray(acc[item.property.toLowerCase()])) {
          return {
            ...acc,
            [item.property.toLowerCase()]: acc[
              item.property.toLowerCase()
            ].concat(item.message),
          };
        }
        return { ...acc, [item.property.toLowerCase()]: [item.message] };
      }, {}),
    [modifier]
  );

  const { currency } = useMenuCurrency();

  const taxRate =
    modifier.item.taxRate || modifier.item.taxRate === 0
      ? modifier.item.taxRate * 100
      : null;

  return (
    <div className="border-b border-gray-300 last:border-b-0 py-2">
      <MenuPreviewItemProperty title="Product ID:" errors={errors["productid"]}>
        <ExternalLink url={modifier.item.deeplink}>
          <span className="mr-1">{modifier.item.productId}</span>
          <LaunchIcon className="w-[1.125rem] h-[1.125rem]" />
        </ExternalLink>
      </MenuPreviewItemProperty>
      <MenuPreviewItemProperty title="Name:" errors={errors["name"]}>
        {modifier.item.name}
      </MenuPreviewItemProperty>
      <MenuPreviewItemProperty title="Price:" errors={errors["price"]}>
        {modifier.item.price || modifier.item.price === 0
          ? `${currency}${modifier.item.price}`
          : null}
      </MenuPreviewItemProperty>
      <MenuPreviewItemProperty title="Tax:" errors={errors["taxrate"]}>
        {taxRate || taxRate === 0 ? `${taxRate}%` : null}
      </MenuPreviewItemProperty>
    </div>
  );
}
