import { useMemo } from "react";
import { DAYS_OF_WEEK_MAP } from "../../constants";
import { Day } from "../../types";

import DayShiftsStatus from "../DayShiftsStatus";

interface MobileHoursTimeTableProps {
  days: Day[];
}

export default function MobileHoursTimeTable({
  days,
}: MobileHoursTimeTableProps) {
  const daysLookup: Record<number, any> = useMemo(() => {
    return days.reduce((next, item) => {
      return { ...next, [item.day]: item };
    }, {});
  }, [days]);

  if (days.length === 0) {
    return (
      <div className="px-24 py-20 text-center">
        Mobile hours are not setup yet.
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      {DAYS_OF_WEEK_MAP.map((day) => (
        <div
          key={`timetable-day-${day.value}`}
          className="flex justify-between mb-1"
        >
          <div role="presentation" aria-label={day.name}>
            {day.name}
          </div>
          <div>
            <DayShiftsStatus
              shifts={daysLookup[day.value] && daysLookup[day.value].shifts}
              disabled={daysLookup[day.value] && !daysLookup[day.value].active}
              className={`${
                daysLookup[day.value] &&
                !daysLookup[day.value].active &&
                "text-gray-500"
              }`}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
