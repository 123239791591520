import { useMemo } from "react";
import { Form } from "react-final-form";
import { useSearchParams } from "react-router-dom";

export default function FeatureHeader({
  children,
}: {
  children: React.ReactNode;
}) {
  const [queryParams, setQueryParams] = useSearchParams();

  const initialValues: Record<string, string> = useMemo(
    () =>
      queryParams.values?.length
        ? Object.fromEntries(queryParams.entries())
        : {},
    [queryParams]
  );

  const onSubmit = (values: Record<string, string>) => {
    setQueryParams(values);
  };
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      subscription={{}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="flex flex-col md:flex-row">
          {children}
        </form>
      )}
    />
  );
}
