import { format, parse } from "date-fns";
import Typography from "../../../../components/Typography";
import { Shift } from "../../types";

interface DayShiftsStatusProps {
  shifts: Shift[];
  disabled: boolean;
  className?: string;
}

enum ShiftsStatus {
  SetupRequired = "Setup Required",
  MultipleShifts = "Multiple Shifts",
  Closed = "Closed",
}

export default function DayShiftsStatus({
  shifts,
  disabled,
  className,
}: DayShiftsStatusProps) {
  let shiftStatus: ShiftsStatus | string = ShiftsStatus.SetupRequired;

  if (shifts.length === 1) {
    if (
      shifts[0].startTime &&
      shifts[0].startTime !== "" &&
      shifts[0].endTime &&
      shifts[0].endTime !== ""
    ) {
      const startTimeHours = parse(shifts[0].startTime, "HH:mm", new Date());
      const endTimeHours = parse(shifts[0].endTime, "HH:mm", new Date());

      const startTime = format(startTimeHours, "hh:mm a");
      const endTime = format(endTimeHours, "hh:mm a");

      shiftStatus = `${startTime} - ${endTime}`;
    }
  }

  if (shifts.length > 1) {
    shiftStatus = ShiftsStatus.MultipleShifts;
  }

  if (disabled) {
    shiftStatus = ShiftsStatus.Closed;
  }

  return (
    <Typography as="body-2" className={className}>
      {shiftStatus}
    </Typography>
  );
}
