import { useState } from "react";
import { Field, useField, useForm } from "react-final-form";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import { DropdownOptionType } from "../../../../components/Dropdown/DropdownOption";
import FormControl from "../../../../components/Forms/FormControl";
import { ChevronDownIcon } from "../../../../components/Icons";
import Typography from "../../../../components/Typography";
import OrderThrottlingStatus from "../OrderThrottlingStatus";

interface OrderThrottlingDailyHoursConfiguratorProps {
  fieldName: string;
  timeSlots: DropdownOptionType[];
}

const OrderThrottlingDailyHoursConfigurator = ({
  fieldName,
  timeSlots,
}: OrderThrottlingDailyHoursConfiguratorProps) => {
  const [open, setOpen] = useState(false);

  const onToggleOpen = () => {
    setOpen(!open);
  };

  const { input: dayNameField } = useField(`${fieldName}.dayName`);
  const { input: capacityField, meta: capacityMeta } = useField(
    `${fieldName}.capacity`
  );
  const { input: startField, meta: startMeta } = useField(
    `${fieldName}.startTime`
  );
  const { input: endField, meta: endMeta } = useField(`${fieldName}.endTime`);

  const hasError = () => {
    if (!!capacityMeta.error && capacityMeta.touched) {
      return true;
    }
    if (!!startMeta.error && startMeta.touched) {
      return true;
    }
    if (!!endMeta.error && endMeta.touched) {
      return true;
    }
    return false;
  };

  const form = useForm();

  return (
    <div
      className={`flex flex-col border rounded-lg px-4 py-3 mb-3 last:mb-0 select-none ${
        open
          ? "border-gray-300"
          : "bg-gray-100 border-gray-100 h-12 overflow-hidden "
      } ${hasError() && "!border-error-500"}`}
    >
      <div className={`flex ${open ? "mb-4" : "mb-2"}`}>
        <div className="flex-grow cursor-pointer" onClick={onToggleOpen}>
          <span className="font-bold tracking-wide">{dayNameField.value}</span>
        </div>
        {startField.value && !open && (
          <OrderThrottlingStatus
            capacity={capacityField.value}
            start={startField.value}
            end={endField.value}
          />
        )}
        {!startField.value && !open && <span className="text-sm">n/a</span>}
        <div className="flex flex-grow-0">
          {open ? (
            <Field
              name="same_hours.clear"
              render={() => (
                <div
                  role="button"
                  className="text-sm underline"
                  onClick={() =>
                    form.batch(() => {
                      form.change(`${fieldName}.startTime`, null);
                      form.change(`${fieldName}.endTime`, null);
                      form.change(`${fieldName}.capacity`, null);
                    })
                  }
                >
                  Clear All
                </div>
              )}
            />
          ) : null}
          <button type="button" onClick={onToggleOpen} className="ml-2">
            {" "}
            <ChevronDownIcon
              className={`h-5 w-5 text-gray-900 transition-transform  ${
                open && "rotate-180"
              }`}
            />
          </button>
        </div>
      </div>
      <div>
        <div className="flex justify-between">
          <div className="w-[45%]">
            <Field
              name={`${fieldName}.endTime`}
              subscription={{ value: true }}
              render={({ input: endInput }) => (
                <Field
                  name={`${fieldName}.startTime`}
                  render={({ input, meta }) => (
                    <FormControl>
                      <Dropdown
                        label="From"
                        input={{
                          ...input,
                          onChange: (val) => {
                            input.onChange(val);
                            endInput.onBlur();
                          },
                        }}
                        meta={meta}
                        options={timeSlots.filter((slot) =>
                          endInput.value !== undefined && endInput.value !== ""
                            ? slot.value < endInput.value
                            : true
                        )}
                        placeholder="--:--"
                      />
                    </FormControl>
                  )}
                />
              )}
            />
          </div>
          <div className="w-[10%] flex px-4 justify-center items-center">
            <span className="px-2 h-0.5 block bg-black" />
          </div>
          <div className="w-[45%]">
            <Field
              name={`${fieldName}.startTime`}
              subscription={{ value: true }}
              render={({ input: startInput }) => (
                <Field
                  name={`${fieldName}.endTime`}
                  render={({ input, meta: endMeta }) => (
                    <FormControl>
                      <Dropdown
                        label="To"
                        input={{
                          ...input,
                          onChange: (val) => {
                            input.onChange(val);
                            startInput.onBlur();
                          },
                        }}
                        meta={endMeta}
                        options={timeSlots.filter((slot) =>
                          startInput.value !== undefined &&
                          startInput.value !== ""
                            ? slot.value > startInput.value
                            : true
                        )}
                        placeholder="--:--"
                      />
                    </FormControl>
                  )}
                />
              )}
            />
          </div>
        </div>
      </div>
      <Field
        name={`${fieldName}.capacity`}
        subscription={{ value: true, error: true, touched: true }}
        render={({ input: capacityInput, meta: capacityMeta }) => (
          <>
            <div className="mt-8 mb-4">
              <Typography as="subtitle-2" className="font-bold">
                How many orders will be accepted?
              </Typography>
              <input
                {...capacityInput}
                type="text"
                className={`bg-white border border-gray-300 rounded-lg px-3 py-2.5 mb-0.5 text-left cursor-pointer focus:ring-1 
                            focus:ring-cinchio-blue-500 focus:outline-0  focus:border-cinchio-blue-500 disabled:bg-gray-100 disabled:text-gray-300 
                            disabled:border-gray-100 ${
                              capacityMeta.touched && !!capacityMeta.error
                                ? "border border-error-500"
                                : ""
                            }`}
              ></input>
              <label htmlFor="capacity" className="font-bold text-sm">
                {" "}
                orders / 15 minute block
              </label>
              {capacityMeta.touched && !!capacityMeta.error ? (
                <Typography className="text-xs text-red-500 h-6">
                  {capacityMeta.error}
                </Typography>
              ) : (
                <Typography as="subtitle-2" className="text-gray-500">
                  5 is minimum, 999 maximum
                </Typography>
              )}
            </div>
          </>
        )}
      />
    </div>
  );
};

export default OrderThrottlingDailyHoursConfigurator;
