import PercentIcon from "../../../../components/Icons/PercentIcon";

export default function DiscountsEmptyState() {
  return (
    <div className="flex flex-grow justify-center mt-0">
      <div className="flex flex-col items-center w-52 mt-20 md:-mt-20 justify-center">
        <PercentIcon className="text-black w-12 h-12 mb-2.5" />
        <p className="text-gray-500 text-center">No discounts.</p>

        <p className="text-gray-500 text-center">Start by adding a new one.</p>
      </div>
    </div>
  );
}
