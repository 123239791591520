import { useQuery } from "@tanstack/react-query";
import { RESTAURANT_CAPACITY_QUERY_KEY } from "../../features/Stores/constants";
import { fetchRestaurantCapacity } from "../../services/restaurants";
import useOrgId from "../organizations/useOrgId";

export default function useCapacity(restaurantId: string) {
  const orgId = useOrgId();

  return useQuery(
    [RESTAURANT_CAPACITY_QUERY_KEY, orgId, restaurantId],
    () => fetchRestaurantCapacity(restaurantId),
    {
      enabled: restaurantId !== "",
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1 * 60 * 1000, // 1 min
    }
  );
}
