interface PartnerFeaturesContentProps {
  children?: React.ReactNode;
  className?: string;
}
export default function PartnerFeaturesContent({
  children,
  className,
}: PartnerFeaturesContentProps) {
  return <div className={`flex-auto ${className}`}>{children}</div>;
}
