import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import Button from "../Buttons/Button";
import { ExclamationIcon } from "../Icons";
import { datadogLogs } from "@datadog/browser-logs";

const InternalErrorBoundary = ({
  resetErrorBoundary,
  featureTitle,
  recoveryPath,
  recoveryHintComponent,
}: FallbackProps & {
  featureTitle: string;
  recoveryPath: string;
  recoveryHintComponent?: React.ReactNode;
}) => {
  const navigate = useNavigate();

  const onRecovery = () => {
    navigate(recoveryPath);
    resetErrorBoundary();
  };

  return (
    <div className="flex flex-col flex-auto h-full ">
      <div className="flex flex-auto justify-center">
        <div className="flex flex-col items-center w-72 xs:w-80 md:w-96 mt-0 md:justify-center">
          <ExclamationIcon className="w-12 h-12 mb-2.5 text-red-500" />
          <p className="text-red-500 text-center text-lg mb-4">
            Something went wrong.
          </p>
          <p className="text-center mb-4 text-sm">
            {featureTitle} encountered an error.{" "}
          </p>
          <p className="text-center mb-4 text-sm">
            {recoveryHintComponent
              ? "Please see the Hints below on how to remedy the error or contact support."
              : "Please contact support."}
          </p>
          {recoveryHintComponent && recoveryHintComponent}
          <div className="flex flex-col w-72 xs:w-80 md:w-96 mb-6 bg-gray-100 border border-gray-300 rounded-lg p-4">
            <div className="flex flex-col md:flex-row items-center justify-center">
              <Button className="md:mr-2" onClick={() => resetErrorBoundary()}>
                Try again
              </Button>
              <Button onClick={() => onRecovery()}>
                Return to {featureTitle}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function FeatureErrorBoundary({
  featureTitle,
  recoveryPath,
  recoveryHintComponent,
  children,
}: {
  featureTitle: string;
  recoveryPath: string;
  recoveryHintComponent?: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <ErrorBoundary
      fallbackRender={(props) => (
        <InternalErrorBoundary
          {...props}
          featureTitle={featureTitle}
          recoveryPath={recoveryPath}
          recoveryHintComponent={recoveryHintComponent}
        />
      )}
      onError={(error) => {
        const errorLog = {
          application_event: `connect.unexpected_error`,
          error: error,
          error_message: error.message,
        };

        datadogLogs.logger.error("Connect Unexpected Error", errorLog);
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
