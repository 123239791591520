import http from "./http";

import {
  PARTNER_RESTAURANTS_API_ENDPOINT,
  RESTAURANT_CAPACITY_API_ENDPOINT,
  RESTAURANT_UPDATE_ADVANCED_ORDERING_STATUS_API_ENDPOINT,
  RESTAURANT_UPDATE_HOURS_API_ENDPOINT,
  RESTAURANT_UPDATE_SPECIAL_INSTRUCTIONS_API_ENDPOINT,
} from "../config/servicesEndpoints";
import {
  CapacityInterface,
  CapacityScheduleInterface,
  PartnerRestaurantInterface,
  StoreInterface,
} from "../features/Stores/types";
import { sprintf } from "sprintf-js";

import { Day } from "../features/Stores/types";

type Restaurants = {
  [key: string]: PartnerRestaurantInterface;
};

export function fetchPartnerRestaurants(
  partner: string | null
): Promise<PartnerRestaurantInterface[]> {
  return http
    .get(sprintf(PARTNER_RESTAURANTS_API_ENDPOINT, { partnerId: partner }))
    .then(({ data }: { data: Restaurants }) => {
      const restaurants: PartnerRestaurantInterface[] = Object.keys(data).map(
        (key) => {
          return {
            ...data[key],
            restaurantId: key,
          };
        }
      );
      return restaurants;
    });
}

export function updateRestaurantHours(
  restaurant: string,
  days: Day[]
): Promise<StoreInterface | void> {
  return http
    .put(
      sprintf(RESTAURANT_UPDATE_HOURS_API_ENDPOINT, {
        restaurantId: restaurant,
      }),
      { days }
    )
    .then(
      ({
        data,
      }: {
        data: StoreInterface & { restaurantName: string }; // update hours returns restaurantName instead of just name
      }) => {
        return {
          ...data,
          days: data.days.map((current) => ({
            day: current.day,
            active: current.active,
            shifts: current.shifts.map((shift) => ({
              startTime: shift.startTime,
              endTime: shift.endTime,
            })),
          })),
        };
      }
    );
}

export function updateRestaurantAdvancedOrderingStatus(
  restaurantId: string,
  isActive: boolean
): Promise<{ isActive: boolean }> {
  return http
    .put(
      sprintf(RESTAURANT_UPDATE_ADVANCED_ORDERING_STATUS_API_ENDPOINT, {
        restaurantId,
      }),
      { isActive }
    )
    .then((response) => response.data);
}

export function fetchRestaurantCapacity(
  restaurantId: string
): Promise<CapacityInterface> {
  return http
    .get(sprintf(RESTAURANT_CAPACITY_API_ENDPOINT, { restaurantId }))
    .then((response) => response.data);
}

export function updateRestaurantCapacityStatus(
  restaurantId: string,
  isActive: boolean
): Promise<{ isActive: boolean }> {
  return http
    .put(
      sprintf(`${RESTAURANT_CAPACITY_API_ENDPOINT}/active`, { restaurantId }),
      { isActive }
    )
    .then((response) => response.data.schedule);
}

export function addRestaurantCapacitySchedule(
  restaurantId: string,
  schedule: CapacityScheduleInterface[]
): Promise<CapacityScheduleInterface[]> {
  return http
    .post(sprintf(`${RESTAURANT_CAPACITY_API_ENDPOINT}`, { restaurantId }), {
      schedule,
    })
    .then((response) => response.data);
}

export function updateRestaurantCapacitySchedule(
  restaurantId: string,
  schedule: CapacityScheduleInterface[]
): Promise<CapacityScheduleInterface[]> {
  return http
    .put(sprintf(`${RESTAURANT_CAPACITY_API_ENDPOINT}`, { restaurantId }), {
      schedule,
    })
    .then((response) => response.data);
}

export function updateSpecialInstructions(
  restaurantId: string,
  isActive: boolean
): Promise<{ isActive: boolean }> {
  return http
    .put(
      sprintf(RESTAURANT_UPDATE_SPECIAL_INSTRUCTIONS_API_ENDPOINT, {
        restaurantId,
      }),
      { isActive }
    )
    .then((response) => response.data);
}
