import { useQuery } from "@tanstack/react-query";
import { PARTNER_DISCOUNTS_QUERY_KEY } from "../../features/Discounts/constants";
import { fetchPartnerDiscounts } from "../../services/discounts";
import useOrgId from "../organizations/useOrgId";

export default function usePartnerDiscounts(partner: string | null) {
  const orgId = useOrgId();
  return useQuery(
    [PARTNER_DISCOUNTS_QUERY_KEY, orgId, partner],
    () => fetchPartnerDiscounts(partner),
    {
      enabled: partner !== "",
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1 * 60 * 1000, // 1min
    }
  );
}
