import { useState } from "react";
import BadgeDisabled from "../../../../components/Badges/BadgeDisabled";
import BadgeSuccess from "../../../../components/Badges/BadgeSuccess";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import FeatureWidget from "../../../../components/FeatureWidget";
import useCapacity from "../../../../hooks/capacity/useCapacity";
import useStore from "../../../../hooks/stores/useStore";
import OrderThrottlingSidePanel from "../OrderThrottlingSidePanel";
import OrderThrottlingTable from "../OrderThrottlingTable";

interface OrderThrottlingWidgetProps {
  storeId: string;
  orgId: string | undefined;
}
const OrderThrottlingWidget = ({
  storeId,
  orgId,
}: OrderThrottlingWidgetProps) => {
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const { data: store } = useStore(storeId);

  const { data: capacity } = useCapacity(storeId);

  return (
    <>
      {store ? (
        <div>
          {capacity && (
            <FeatureWidget
              title={
                <div className="featureStatus flex justify-between items-start">
                  Order throttling
                  {store.hasCapacity ? (
                    <BadgeSuccess>Enabled</BadgeSuccess>
                  ) : (
                    <BadgeDisabled>Disabled</BadgeDisabled>
                  )}
                </div>
              }
              content={
                <OrderThrottlingTable
                  schedule={capacity.schedule}
                  store={store}
                />
              }
              action={
                <PrimaryButton className="w-full" onClick={onOpen}>
                  Manage order throttling
                </PrimaryButton>
              }
            />
          )}
          {capacity && (
            <OrderThrottlingSidePanel
              open={open}
              orgId={orgId}
              store={store}
              schedule={capacity.schedule}
              onClose={onClose}
            />
          )}
        </div>
      ) : null}
    </>
  );
};

export default OrderThrottlingWidget;
