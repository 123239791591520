import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { Field, Form } from "react-final-form";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import SidePanel from "../../../../components/SidePanel";
import { SidePanelVisibilityProps } from "../../../../components/SidePanel/SidePanel";
import SidePanelContent from "../../../../components/SidePanel/SidePanelContent";
import SidePanelFooter from "../../../../components/SidePanel/SidePanelFooter";
import SidePanelHeader from "../../../../components/SidePanel/SidePanelHeader";
import SidePanelLayout from "../../../../components/SidePanel/SidePanelLayout";
import Switch from "../../../../components/Switch/Switch";
import Typography from "../../../../components/Typography";
import useUpdateCapacityStatus from "../../../../hooks/restaurants/useUpdateCapacity";
import {
  DAYS_OF_WEEK_MAP,
  RESTAURANT_CAPACITY_QUERY_KEY,
  STORE_QUERY_KEY,
} from "../../constants";
import { CapacityScheduleInterface, StoreInterface } from "../../types";
import { toast } from "react-hot-toast";
import OrderThrottlingSameHours from "../OrderThrottlingSameHours";
import generateTimeSlots from "../../utils/generateTimeSlots";
import arrayMutators from "final-form-arrays";
import isSameCapacity from "../../utils/isSameCapacity";
import OrderThrottlingDailyHours from "../OrderThrottlingDailyHours";
import useAddCapacitySchedule from "../../../../hooks/restaurants/useAddCapacitySchedule";
import useUpdateCapacitySchedule from "../../../../hooks/restaurants/useUpdateCapacitySchedule";
import { AxiosError } from "axios";
import validateCapacitySchema from "../../validation/validateCapacity";
import { useValidationSchema } from "../../../../hooks/validations/useValidationSchema";
import InfoMessage from "../../../../components/InfoMessage";
import FormControl from "../../../../components/Forms/FormControl";

interface OrderThrottlingSidePanelProps extends SidePanelVisibilityProps {
  store: StoreInterface;
  orgId: string | undefined;
  schedule: CapacityScheduleInterface[];
}

const timeSlots = generateTimeSlots({
  start: 0,
  end: 24,
  interval: 15,
  displayTimeFormat: "hh:mm a",
  valueTimeFormat: "HH:mm",
});

type ScheduleSubmitValues = {
  isActive: boolean;
  same_hours_active: boolean;

  same_hours: {
    active?: boolean;
    startTime: string | null;
    endTime: string | null;
    capacity: number | null;
    days: CapacityScheduleInterface[];
  };

  daily_hours: {
    days: CapacityScheduleInterface[];
  };
};

const OrderThrottlingSidePanel = ({
  open,
  store,
  orgId,
  schedule,
  onClose,
}: OrderThrottlingSidePanelProps) => {
  const queryClient = useQueryClient();

  const validateCapacity = useValidationSchema(validateCapacitySchema);

  const capacityLookup: Record<number, any> = useMemo(() => {
    return schedule.reduce((next, item, index) => {
      return { ...next, [index]: item };
    }, {});
  }, [schedule]);

  const sameHours = useMemo(
    () => store && schedule && isSameCapacity(schedule),
    [store, schedule]
  );

  const emptySameHoursDays = DAYS_OF_WEEK_MAP.map((day) => ({
    day: day.name,
    dayName: day.name,
    dayShortName: day.shortName,
    active: false,
    startTime: null,
    endTime: null,
    capacity: null,
  }));

  const emptyDailyHoursDays = DAYS_OF_WEEK_MAP.map((day) => ({
    day: day.name,
    dayName: day.name,
    active: true,
    dayShortName: day.shortName,
    startTime: null,
    endTime: null,
    capacity: null,
  }));

  const prefilledDays = DAYS_OF_WEEK_MAP.map((day) => {
    return {
      day: day.name,
      dayName: day.name,
      dayShortName: day.shortName,
      active: capacityLookup[day.value]
        ? capacityLookup[day.value].capacity > 0
        : null,
      startTime: capacityLookup[day.value]
        ? capacityLookup[day.value].startTime
        : null,
      endTime: capacityLookup[day.value]
        ? capacityLookup[day.value].endTime
        : null,
      capacity: capacityLookup[day.value]
        ? capacityLookup[day.value].capacity
        : null,
    };
  });

  const initialValues = useMemo(() => {
    return {
      isActive: store.hasCapacity,
      same_hours_active: sameHours,
      same_hours: {
        active: sameHours,
        startTime:
          sameHours && schedule.length > 0 ? schedule[0].startTime : null,
        endTime: sameHours && schedule.length ? schedule[0].endTime : null,
        days: sameHours ? prefilledDays : emptySameHoursDays,
        capacity: sameHours && schedule.length ? schedule[0].capacity : null,
      },
      daily_hours: {
        days: sameHours ? emptyDailyHoursDays : prefilledDays,
      },
    };
  }, [
    store.hasCapacity,
    sameHours,
    schedule,
    emptyDailyHoursDays,
    emptySameHoursDays,
    prefilledDays,
  ]);

  const timeSlotsAsOptions = useMemo(
    () =>
      timeSlots.map((slot) => ({
        label: slot.displayValue,
        value: slot.value,
      })),
    []
  );

  const { mutateAsync: updateCapacityStatus } = useUpdateCapacityStatus();
  const { mutateAsync: addCapacitySchedule } = useAddCapacitySchedule();
  const { mutateAsync: updateCapacitySchedule } = useUpdateCapacitySchedule();

  const onSubmit = async (values: ScheduleSubmitValues) => {
    // users is disabling order throttle
    // so we dont care about updating the schedule
    if (!values.isActive) {
      return updateCapacityStatus({
        isActive: false,
        restaurantId: store.id,
      })
        .then(() => {
          queryClient.invalidateQueries([STORE_QUERY_KEY, orgId, store.id]);
          queryClient.invalidateQueries([
            RESTAURANT_CAPACITY_QUERY_KEY,
            orgId,
            store.id,
          ]);
          onClose();
          toast.success("Order throttling successfully disabled.");
        })
        .catch(() => {
          toast.error("Failed to disable Order throttling. Please try again.");
        });
    }

    const newSchedule = values.same_hours_active
      ? values.same_hours.days.map((day) => {
          if (!day.active) {
            return {
              day: day.day,
              capacity: null,
              startTime: null,
              endTime: null,
            };
          }
          return {
            day: day.day,
            capacity: day.capacity,
            startTime: day.startTime,
            endTime: day.endTime,
          };
        })
      : values.daily_hours.days.map((day) => {
          return {
            day: day.day,
            capacity: day.capacity ? Number(day.capacity) : day.capacity,
            startTime: day.startTime,
            endTime: day.endTime,
          };
        });

    // Create schedule
    if (schedule.length === 0) {
      return addCapacitySchedule({
        restaurantId: store.id,
        schedule: newSchedule,
      })
        .then(() => {
          return updateCapacityStatus({
            restaurantId: store.id,
            isActive: values.isActive,
          })
            .then(() => {
              queryClient.invalidateQueries([STORE_QUERY_KEY, orgId, store.id]);

              queryClient.invalidateQueries([
                RESTAURANT_CAPACITY_QUERY_KEY,
                orgId,
                store.id,
              ]);

              onClose();
              toast.success("Order throttling successfully created.");
            })
            .catch(() => {
              toast.error(
                "Capacity Schedule was set, but enabling Order throttling failed. Please try again."
              );
            });
        })
        .catch((error) => {
          const _error = error as AxiosError<{ message: string }>;
          toast.error(
            `Failed to create Capacity Schedule. ${_error.response?.data.message}`
          );
        });
    }

    // Update schedule
    if (schedule.length > 0) {
      return updateCapacitySchedule({
        restaurantId: store.id,
        schedule: newSchedule,
      })
        .then(() => {
          return updateCapacityStatus({
            restaurantId: store.id,
            isActive: values.isActive,
          })
            .then(() => {
              queryClient.invalidateQueries([STORE_QUERY_KEY, orgId, store.id]);

              queryClient.invalidateQueries([
                RESTAURANT_CAPACITY_QUERY_KEY,
                orgId,
                store.id,
              ]);

              onClose();
              toast.success("Order throttling successfully updated.");
            })
            .catch(() => {
              toast.error(
                "Capacity Schedule was set, but enabling Order throttling failed. Please try again."
              );
            });
        })
        .catch((error) => {
          const _error = error as AxiosError<{ message: string }>;
          toast.error(
            `Failed to update Capacity Schedule. ${_error.response?.data.message}`
          );
        });
    }
  };

  return (
    <SidePanel open={open} onClose={onClose}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validateCapacity}
        mutators={{ ...arrayMutators }}
        keepDirtyOnReinitialize={true}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="h-full">
            <SidePanelLayout>
              <SidePanelHeader
                title="Order throttling"
                subtitle={store.name}
                onClose={onClose}
              />
              <SidePanelContent>
                <div className="flex-col bg-gray-50 p-4 rounded-lg mb-8">
                  <div className="flex">
                    <div className="flex-1 flex-row">
                      <Typography as="body-1" className="font-semibold mb-1.5">
                        Enable order throttling
                      </Typography>
                      <span className="block text-xs text-gray-500 pr-7">
                        Enabling throttling will also enable Advanced ordering.
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Field
                        name="isActive"
                        type="checkbox"
                        render={({ input, meta }) => (
                          <FormControl>
                            <Switch
                              label={input.checked ? "On" : "Off"}
                              labelPosition="left"
                              input={input}
                              meta={meta}
                              className="mb-0"
                            />
                          </FormControl>
                        )}
                      />
                    </div>
                  </div>

                  <Field
                    name="isActive"
                    type="checkbox"
                    subscription={{ value: true }}
                    render={({ input }) =>
                      input.checked &&
                      !store.hasAdvancedOrdering &&
                      !store.hasCapacity ? (
                        <InfoMessage className="mt-6">
                          <Typography as="body-2">
                            Advanced ordering will be turned on and available to
                            guests once you save these settings. Guests will be
                            able to place same day orders in advance.
                          </Typography>
                        </InfoMessage>
                      ) : null
                    }
                  />
                </div>
                <Field
                  name="isActive"
                  type="checkbox"
                  render={({ input }) =>
                    input.checked ? (
                      <>
                        <div className="flex justify-between">
                          <div>Set Same Hours Every Day</div>
                          <div>
                            <Field<boolean>
                              name="same_hours_active"
                              type="checkbox"
                              validateFields={[]}
                              render={({ input, meta }) => (
                                <FormControl>
                                  <Switch input={input} meta={meta} />
                                </FormControl>
                              )}
                            />
                          </div>
                        </div>

                        <Field
                          name="same_hours_active"
                          type="checkbox"
                          subscription={{ value: true }}
                          validateFields={[]}
                          render={({ input }) =>
                            input.checked ? (
                              <OrderThrottlingSameHours
                                timeSlots={timeSlotsAsOptions}
                                emptyHoursDays={emptySameHoursDays}
                              />
                            ) : (
                              <OrderThrottlingDailyHours
                                timeSlots={timeSlotsAsOptions}
                              />
                            )
                          }
                        />
                      </>
                    ) : null
                  }
                />
              </SidePanelContent>
              <SidePanelFooter>
                <div className="flex justify-evenly">
                  <SecondaryButton onClick={onClose} className="w-full mr-1">
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    className="w-full ml-1"
                    type="submit"
                    disabled={submitting}
                  >
                    Save
                  </PrimaryButton>
                </div>
              </SidePanelFooter>
            </SidePanelLayout>
          </form>
        )}
      ></Form>
    </SidePanel>
  );
};

export default OrderThrottlingSidePanel;
