import * as yup from "yup";

const getError = (context: yup.TestContext, path: string, message: string) => {
  return context.createError({
    path: `${context.path}.${path}`,
    message: message,
  });
};

const validateTouched = yup
  .object()
  .shape({
    dayName: yup.string().nullable(),
    active: yup.boolean().nullable(),
    startTime: yup.string().nullable(),
    endTime: yup.string().nullable(),
    capacity: yup
      .number()
      .min(5, "Capacity must be over 5")
      .max(999, "Capacity must be less than 999")
      .typeError("Capacity must be a number between 5 and 999")
      .nullable(),
  })
  .test("form-touched", "please fill out fields", function(day, context) {
    if (day.startTime && !day.endTime && !day.capacity) {
      return (
        getError(context, "endTime", "Select a time"),
        getError(context, "capacity", "Please add a capacity")
      );
    }
    if (day.startTime && !day.endTime && day.capacity) {
      return getError(context, "endTime", "Select a time");
    }

    if (day.startTime && day.endTime && !day.capacity) {
      return getError(context, "capacity", "Please add a capacity");
    }

    if (!day.startTime && !day.endTime && day.capacity) {
      return (
        getError(context, "startTime", "Select a time"),
        getError(context, "endTime", "Select a time")
      );
    }

    if (!day.startTime && day.endTime && !day.capacity) {
      return (
        getError(context, "startTime", "Select a time"),
        getError(context, "capacity", "Please add a capacity")
      );
    }

    if (!day.startTime && !day.endTime && day.capacity) {
      return (
        getError(context, "startTime", "Select a time"),
        getError(context, "endTime", "Select a time")
      );
    }
    if (!day.startTime && day.endTime && day.capacity) {
      return getError(context, "startTime", "Select a time");
    }
    return true;
  });

const validateSameHours = yup
  .array()
  .of(
    yup.object().shape({
      dayName: yup.string().nullable(),
      active: yup.boolean().nullable(),
      startTime: yup.string().nullable(),
      endTime: yup.string().nullable(),
      capacity: yup.number().nullable(),
    })
  )
  .test(
    "atleast-one-day",
    "Please select days of week",
    function(days, context) {
      const atleastOneDayIsActive = days?.find((day) => day.active);
      const startSet = days?.find((day) => day.startTime);
      const endSet = days?.find((day) => day.endTime);
      const capacitySet = days?.find((day) => day.capacity);

      if (
        !!atleastOneDayIsActive === false &&
        !!startSet === false &&
        !!endSet === false &&
        !!capacitySet === false
      ) {
        return true;
      }
      if (!!atleastOneDayIsActive === false) {
        return context.createError({
          path: `days_error`,
          message: "Please select days of the week",
        });
      }

      if (!!startSet === false) {
        return context.createError({
          path: "same_hours.startTime",
          message: "Select a time",
        });
      }

      if (!!endSet === false) {
        return context.createError({
          path: "same_hours.endTime",
          message: "Select a time",
        });
      }

      if (!!capacitySet === false) {
        return context.createError({
          path: "same_hours.capacity",
          message: "Please add a capacity",
        });
      }

      return true;
    }
  );

const validateDailyHours = yup.array().of(validateTouched);

const validateCapacitySchema = yup.object({
  same_hours_active: yup.boolean(),
  same_hours: yup.mixed().when("same_hours_active", {
    is: true,
    then: () =>
      yup.object({
        active: yup.boolean(),
        startTime: yup.string().nullable(),
        endTime: yup.string().nullable(),
        capacity: yup
          .number()
          .min(5, "Capacity must be over 5")
          .max(999, "Capacity must be less than 999")
          .typeError("Capacity must be a number between 5 and 999")
          .nullable(),
        days: yup.mixed().when("active", {
          is: true,
          then: () => validateSameHours,
          otherwise: () => yup.object().nullable(),
        }),
      }),
    otherwise: () => yup.object().nullable(),
  }),
  daily_hours: yup.mixed().when("same_hours_active", {
    is: false,
    then: () =>
      yup.object({
        days: validateDailyHours,
      }),
    otherwise: () => yup.object().nullable(),
  }),
});

export default validateCapacitySchema;
