import { Outlet, useMatch, useSearchParams } from "react-router-dom";
import FeatureContentWrapper from "../../components/FeatureContentWrapper";
import FeatureHeader from "../../components/FeatureHeader";
import FeatureHeaderMenuDropdown from "../../components/FeatureHeader/FeatureHeaderMenuDropdown";
import FeatureHeaderSiteDropdown from "../../components/FeatureHeader/FeatureHeaderSiteDropdown";
import FeatureHeaderStoreDropdown from "../../components/FeatureHeader/FeatureHeaderStoreDropdown";
import { ROUTES } from "../../constants/routes";

export default function Stores() {
  const [queryParams] = useSearchParams();

  const menuManagement = useMatch(
    ROUTES.TOP_LEVEL_ORG +
      "/" +
      ROUTES.STORES +
      "/" +
      ROUTES.STORES_MENU_MANAGEMENT
  );
  const discounts = useMatch(ROUTES.TOP_LEVEL_ORG + "/" + ROUTES.DISCOUNTS);

  const contextValues = {
    region: queryParams.get("region") || "",
    site: queryParams.get("site") || "",
    store: queryParams.get("store") || "",
    menu: queryParams.get("menu") || "",
  };

  return (
    <FeatureContentWrapper>
      <div className="border-b border-gray-300 mb-7">
        <FeatureHeader>
          <FeatureHeaderSiteDropdown />
          {!discounts && <FeatureHeaderStoreDropdown />}
          {menuManagement && !discounts && <FeatureHeaderMenuDropdown />}
        </FeatureHeader>
      </div>
      <Outlet context={contextValues} />
    </FeatureContentWrapper>
  );
}
