import * as yup from "yup";

const validateSameHours = yup
  .array()
  .of(
    yup.object().shape({
      day: yup.number(),
      dayName: yup.string(),
      active: yup.boolean(),
      shifts: yup.array().of(
        yup.object().shape({
          start: yup.string(),
          end: yup.string(),
        })
      ),
    })
  )
  .test(
    "atleast-one-day",
    "Please select days of week",
    function(days, context) {
      const atleastOneDayIsActive = days?.find((day) => day.active);

      if (!!atleastOneDayIsActive === false) {
        return context.createError({
          path: `days_error`,
          message: "Please select days of the week",
        });
      }

      return true;
    }
  );

const validateDailyHours = yup.array().of(
  yup.object().shape({
    day: yup.number(),
    dayName: yup.string(),
    active: yup.boolean(),
    shifts: yup.mixed().when("active", {
      is: true,
      then: () =>
        yup.array().of(
          yup.object({
            startTime: yup.string().required("Select a time"),
            endTime: yup.string().required("Select a time"),
          })
        ),
      otherwise: () => yup.array(),
    }),
  })
);

const validateMobileHoursSchema = yup.object({
  same_hours_active: yup.boolean(),
  same_hours: yup.mixed().when("same_hours_active", {
    is: true,
    then: () =>
      yup.object({
        active: yup.boolean(),
        startTime: yup.string().when("active", {
          is: true,
          then: () => yup.string().required("Select a time"),
          otherwise: () => yup.string().nullable(),
        }),
        endTime: yup.string().when("active", {
          is: true,
          then: () =>
            yup
              .string()
              .required("Select a time")
              .test(
                "hours-test",
                "From must be greater than To 1",
                function(value, context) {
                  if (value === "" || value === undefined) {
                    return context.createError({
                      message: "Select a time",
                    });
                  }
                  return this.parent.startTime < value;
                }
              ),
          otherwise: () => yup.string().nullable(),
        }),
        days: yup.mixed().when("active", {
          is: true,
          then: () => validateSameHours,
          otherwise: () => yup.object().nullable(),
        }),
      }),
    otherwise: () => yup.object().nullable(),
  }),
  daily_hours: yup.mixed().when("same_hours_active", {
    is: false,
    then: () =>
      yup.object({
        days: validateDailyHours,
      }),
    otherwise: () => yup.object().nullable(),
  }),
});

export default validateMobileHoursSchema;
