import { useQuery } from "@tanstack/react-query";
import { PARTNER_STANDARD_DISCOUNTS_QUERY_KEY } from "../../features/Discounts/constants";
import { fetchPartnerStandardDiscounts } from "../../services/discounts";
import useOrgId from "../organizations/useOrgId";

export default function useStandardDiscounts(type: string) {
  const orgId = useOrgId();
  return useQuery(
    [PARTNER_STANDARD_DISCOUNTS_QUERY_KEY, orgId, type],
    () => fetchPartnerStandardDiscounts(type),
    {
      enabled: type !== "",
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1 * 60 * 1000,
    }
  );
}
