import { useQuery } from "@tanstack/react-query";
import { RESTAURANT_MENU_QUERY_KEY } from "../../features/Stores/MenuManagement/constants";
import { fetchMenu } from "../../services/menus";
import useOrgId from "../organizations/useOrgId";

export default function useMenu(
  restaurantId: string,
  menuId: number | undefined
) {
  const orgId = useOrgId();
  return useQuery(
    [RESTAURANT_MENU_QUERY_KEY, orgId, restaurantId, menuId],
    () => fetchMenu(restaurantId, menuId),
    {
      enabled: restaurantId !== "" && menuId !== undefined,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}
