interface DiscountsContentProps {
  children?: React.ReactNode;
  className?: string;
}
export default function DiscountsContent({
  children,
  className,
}: DiscountsContentProps) {
  return <div className={`flex-auto ${className}`}>{children}</div>;
}
