import Dropdown from "../../../../components/Dropdown/Dropdown";
import { Field } from "react-final-form";
import { DropdownOptionType } from "../../../../components/Dropdown/DropdownOption";
import Switch from "../../../../components/Switch/Switch";
import { FieldArray } from "react-final-form-arrays";
import FormControl from "../../../../components/Forms/FormControl";

interface RestaurantSameHoursProps {
  timeSlots: DropdownOptionType[];
}

export default function RestaurantSameHours({
  timeSlots,
}: RestaurantSameHoursProps) {
  return (
    <div className="flex flex-col border border-gray-300 rounded-lg px-4 py-3">
      <div className="flex justify-between ">
        <span className="font-bold tracking-wide">Daily Hours</span>
        <div>
          <Field<boolean>
            name="same_hours.active"
            type="checkbox"
            render={({ input, meta }) => (
              <Switch
                input={input}
                meta={meta}
                label={input.checked ? "Open" : "Closed"}
                labelPosition="left"
                className="!mb-4"
              />
            )}
          />
        </div>
      </div>

      <Field
        name="same_hours.active"
        subscription={{ value: true }}
        render={({ input: isActiveInput }) => (
          <div>
            <div className="flex justify-between">
              <div className="w-[45%]">
                <Field
                  name="same_hours.endTime"
                  subscription={{ value: true }}
                  render={({ input: endInput }) => (
                    <Field
                      name="same_hours.startTime"
                      render={({ input, meta }) => (
                        <FormControl>
                          <Dropdown
                            label="From"
                            input={{
                              ...input,
                              onChange: (val) => {
                                input.onChange(val);
                                input.onBlur();
                                endInput.onBlur();
                              },
                            }}
                            meta={meta}
                            options={timeSlots.filter((slot) =>
                              endInput.value !== undefined &&
                              endInput.value !== ""
                                ? slot.value < endInput.value
                                : true
                            )}
                            placeholder="--:--"
                            disabled={!isActiveInput.value}
                          />
                        </FormControl>
                      )}
                    />
                  )}
                />
              </div>
              <div className="w-[10%] flex px-4 justify-center items-center">
                <span className="px-2 h-0.5 block bg-black" />
              </div>
              <div className="w-[45%]">
                <Field
                  name="same_hours.startTime"
                  subscription={{ value: true }}
                  render={({ input: startInput }) => (
                    <Field
                      name="same_hours.endTime"
                      render={({ input, meta }) => (
                        <FormControl>
                          <Dropdown
                            label="To"
                            input={{
                              ...input,
                              onChange: (val) => {
                                input.onChange(val);
                                input.onBlur();
                                startInput.onBlur();
                              },
                            }}
                            meta={meta}
                            options={timeSlots.filter((slot) =>
                              startInput.value !== undefined &&
                              startInput.value !== ""
                                ? slot.value > startInput.value
                                : true
                            )}
                            placeholder="--:--"
                            disabled={!isActiveInput.value}
                          />
                        </FormControl>
                      )}
                    />
                  )}
                />
              </div>
            </div>
            <div className="">
              <Field
                name="days_error"
                subscription={{ error: true, touched: true }}
                render={({ meta: daysErrorMeta }) => (
                  <div>
                    <div className="flex justify-between">
                      <FieldArray
                        name="same_hours.days"
                        render={({ fields }) =>
                          fields.map((name, index) => (
                            <div key={name}>
                              <Field
                                key={name}
                                name={`${name}.active`}
                                type="checkbox"
                                render={({ input }) => (
                                  <span>
                                    <input
                                      {...input}
                                      id={`${name}-active`}
                                      aria-label={`${name}-active`}
                                      className="sr-only peer"
                                      disabled={!isActiveInput.value}
                                    />
                                    <label
                                      htmlFor={`${name}-active`}
                                      className={`flex justify-center items-center text-xs  border rounded-full w-8 h-8 cursor-pointer 
                        peer-disabled:bg-gray-100 peer-disabled:text-gray-300 
                        peer-checked:bg-cinchio-blue-500 peer-checked:text-white
                        peer-checked:peer-disabled:bg-cinchio-blue-100 peer-checked:peer-disabled:text-white ${
                          daysErrorMeta.touched && !!daysErrorMeta.error
                            ? "border-error-500"
                            : ""
                        }`}
                                    >
                                      {fields.value[index].dayShortName}
                                    </label>
                                  </span>
                                )}
                              />

                              <Field
                                name="same_hours.startTime"
                                subscription={{ value: true }}
                                render={({ input: startInput }) => (
                                  <Field
                                    name={`${name}.shifts[0].startTime`}
                                    initialValue={startInput.value}
                                    render={() => null}
                                  />
                                )}
                              />

                              <Field
                                name="same_hours.endTime"
                                subscription={{ value: true }}
                                render={({ input: endInput }) => (
                                  <Field
                                    name={`${name}.shifts[0].endTime`}
                                    initialValue={endInput.value}
                                    render={() => null}
                                  />
                                )}
                              />
                            </div>
                          ))
                        }
                      />
                    </div>

                    {daysErrorMeta.touched && !!daysErrorMeta.error ? (
                      <p className="text-xs text-red-500 h-6 mt-2">
                        {daysErrorMeta.error}
                      </p>
                    ) : null}
                  </div>
                )}
              />
            </div>
          </div>
        )}
      />
    </div>
  );
}
