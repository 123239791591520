import { Field, Form } from "react-final-form";
import Switch from "../Switch/Switch";
import ActionModal from "../ActionModal";
import SecondaryButton from "../Buttons/SecondaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";
import { PERMISSIONS_FEATURE_MANAGEMENT } from "../../constants/permissions";
import useUpdatePartnerDiscountsFeature from "../../hooks/features/useUpdatePartnerDiscountsFeature";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { PARTNER_FEATURES_QUERY_KEY } from "../../features/FeatureManagement/constants";
import { toast } from "react-hot-toast";
import { FormApi } from "final-form";
import { usePermissions } from "../../hooks/permissions/usePermissions";
import { PartnerFeatureInterface } from "../../features/FeatureManagement/types";

type PartnerFeatureDiscountsProps = Omit<
  PartnerFeatureInterface,
  "needsSetup"
> & {
  orgId: string | undefined;
};

export const DiscountToggle = ({
  orgId,
  partnerId,
  enabled,
}: PartnerFeatureDiscountsProps) => {
  const { hasPermission } = usePermissions();

  const canChangeDiscounts = !hasPermission(
    PERMISSIONS_FEATURE_MANAGEMENT.DISCOUNT_TOGGLE
  );

  const updateDiscountsFeature = useUpdatePartnerDiscountsFeature();
  const queryClient = useQueryClient();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onHideConfirmModal = () => setShowConfirmModal(false);

  const onEnableFeature = async () => {
    setSubmitting(true);

    return updateDiscountsFeature
      .mutateAsync({ partnerId, isActive: true })
      .then(() => {
        queryClient.invalidateQueries([
          PARTNER_FEATURES_QUERY_KEY,
          orgId,
          partnerId,
        ]);
        onHideConfirmModal();
        setSubmitting(false);
      })
      .catch(() => {
        onHideConfirmModal();
        setSubmitting(false);
        toast.error("Failed to turn on Discounts. Please try again.");
      });
  };

  const onDisableFeature = async (
    form: FormApi<Record<string, any>, Partial<Record<string, any>>>
  ) => {
    return updateDiscountsFeature
      .mutateAsync({ partnerId, isActive: false })
      .then(() =>
        queryClient.invalidateQueries([
          PARTNER_FEATURES_QUERY_KEY,
          orgId,
          partnerId,
        ])
      )
      .catch(() => {
        queryClient.invalidateQueries([
          PARTNER_FEATURES_QUERY_KEY,
          orgId,
          partnerId,
        ]);
        form.reset();
        toast.error("Failed to turn off Discounts. Please try again.");
      });
  };

  const initialValues = useMemo(
    () => ({
      discounts_enabled: enabled,
    }),
    [enabled]
  );

  const onSubmit = () => {};
  return (
    <Form
      onSubmit={onSubmit}
      subscription={{}}
      initialValues={initialValues}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="discounts_enabled"
            type="checkbox"
            render={({ input, meta }) => (
              <>
                <Switch
                  label={input.checked ? "On" : "Off"}
                  labelPosition="left"
                  disabled={canChangeDiscounts}
                  input={{
                    ...input,
                    onChange: (e) => {
                      if (e.target.checked === false) {
                        input.onChange(e);
                        onDisableFeature(form);
                      } else {
                        setShowConfirmModal(true);
                      }
                    },
                  }}
                  meta={meta}
                  name="discounts_enabled"
                  className="mb-0"
                />
              </>
            )}
          />

          {showConfirmModal ? (
            <ActionModal
              title="Are you sure you want to enable Discounts in the digital experience?"
              content={""}
              onClose={onHideConfirmModal}
              footer={
                <div>
                  <SecondaryButton
                    className="py-1.5 px-4 mr-2"
                    onClick={onHideConfirmModal}
                  >
                    Not now
                  </SecondaryButton>
                  <PrimaryButton
                    className="py-1.5 px-4"
                    disabled={submitting}
                    onClick={onEnableFeature}
                  >
                    Yes, turn on
                  </PrimaryButton>
                </div>
              }
            />
          ) : null}
        </form>
      )}
    />
  );
};
