import { format, parse } from "date-fns";

interface OrderThrottlingStatusProps {
  day?: string;
  capacity: number | null;
  start: string | null;
  end: string | null;
}

const OrderThrottlingStatus = ({
  day,
  capacity,
  start,
  end,
}: OrderThrottlingStatusProps) => {
  let status = "n/a";
  if (start && end) {
    const startTimeHours = parse(start, "HH:mm", new Date());
    const endTimeHours = parse(end, "HH:mm", new Date());

    const startTime = format(startTimeHours, "hh:mm a");
    const endTime = format(endTimeHours, "hh:mm a");

    status = `${startTime} - ${endTime}  •  ${capacity} orders`;
  }

  return (
    <div className="flex [@media(max-width:360px)]:flex-col [@media(max-width:360px)]:mb-2  justify-between text-sm">
      {day && <div role="presentation">{day}</div>}
      <div>{status}</div>
    </div>
  );
};

export default OrderThrottlingStatus;
