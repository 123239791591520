import { useMemo } from "react";
import ExternalLink from "../../../../../components/ExternalLink";
import LaunchIcon from "../../../../../components/Icons/LauchIcon";
import { useMenuCurrency } from "../../hooks/useMenuCurrency";
import { MenuItemInterface } from "../../types";
import MenuPreviewItemCustomizations from "../MenuPreviewItemCustomizations";
import MenuPreviewItemImage from "../MenuPreviewItemImage";
import MenuPreviewItemProperty from "../MenuPreviewItemProperty";

export default function MenuPreviewItem({ item }: { item: MenuItemInterface }) {
  const errors: Record<string, string[]> = useMemo(
    () =>
      item.errors.reduce((acc: Record<string, string[]>, item) => {
        if (Array.isArray(acc[item.property.toLowerCase()])) {
          return {
            ...acc,
            [item.property.toLowerCase()]: acc[
              item.property.toLowerCase()
            ].concat(item.message),
          };
        }
        return { ...acc, [item.property.toLowerCase()]: [item.message] };
      }, {}),
    [item]
  );

  const { currency } = useMenuCurrency();

  const taxRate =
    item.taxRate || item.taxRate === 0 ? item.taxRate * 100 : null;

  return (
    <div className="pb-4 mb-4 border-b border-gray-300 last:border-b-0">
      <div className="flex justify-between">
        <div>
          <MenuPreviewItemProperty
            title="Product ID:"
            errors={errors["productid"]}
          >
            <ExternalLink url={item.deeplink}>
              <span className="mr-1">{item.productId}</span>
              <LaunchIcon className="w-[1.125rem] h-[1.125rem]" />
            </ExternalLink>
          </MenuPreviewItemProperty>
          <MenuPreviewItemProperty title="Name:" errors={errors["name"]}>
            {item.name}
          </MenuPreviewItemProperty>
          <MenuPreviewItemProperty
            title="Description:"
            errors={errors["description"]}
          >
            {item.description}
          </MenuPreviewItemProperty>
          <MenuPreviewItemProperty title="Price:" errors={errors["price"]}>
            {item.price || item.price === 0 ? `${currency}${item.price}` : null}
          </MenuPreviewItemProperty>
          <MenuPreviewItemProperty title="Tax:" errors={errors["taxrate"]}>
            {taxRate || taxRate === 0 ? `${taxRate}%` : null}
          </MenuPreviewItemProperty>

          <MenuPreviewItemProperty
            title="Price Excludes Tax:"
            errors={errors["pricingnet"]}
          >
            {item.pricingNet ? "Yes" : "No"}
          </MenuPreviewItemProperty>
        </div>
        <MenuPreviewItemImage url={item.imageUrl} errors={errors["imageurl"]} />
      </div>
      {item.modifierGroup.length ? (
        <div className="mt-3">
          <MenuPreviewItemCustomizations modifierGroup={item.modifierGroup} />
        </div>
      ) : null}
    </div>
  );
}
