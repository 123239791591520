import { useState } from "react";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import FeatureWidget from "../../../../components/FeatureWidget";
import useStore from "../../../../hooks/stores/useStore";
import MobileHoursTimeTable from "../MobileHoursTimeTable";
import RestaurantMobileHours from "../RestaurantMobileHours";

interface ManageMobileOrderHoursWidgetProps {
  storeId: string;
  orgId: string | undefined;
}

export default function ManageMobileOrderHoursWidget({
  storeId,
  orgId,
}: ManageMobileOrderHoursWidgetProps) {
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);

  const onClose = () => setOpen(false);

  const { data: store } = useStore(storeId);

  return (
    <div>
      {store ? (
        <div>
          <FeatureWidget
            title="Mobile order hours"
            content={
              <div>
                <MobileHoursTimeTable days={store.days} />
              </div>
            }
            action={
              <PrimaryButton className="w-full" onClick={onOpen}>
                {store.days.length === 0
                  ? "Setup mobile order hours"
                  : "Manage mobile order hours"}
              </PrimaryButton>
            }
          />
          <RestaurantMobileHours
            open={open}
            store={store}
            orgId={orgId}
            onClose={onClose}
          />
        </div>
      ) : null}
    </div>
  );
}
