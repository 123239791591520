import { useQueryClient } from "@tanstack/react-query";
import { FormApi } from "final-form";
import { useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import ActionModal from "../../../../components/ActionModal";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import Switch from "../../../../components/Switch/Switch";
import Typography from "../../../../components/Typography";
import { PERMISSIONS_FEATURE_MANAGEMENT } from "../../../../constants/permissions";
import { ROUTES } from "../../../../constants/routes";
import useUpdatePartnerRoundUpsFeature from "../../../../hooks/features/useUpdatePartnerRoundUpsFeature";
import { usePermissions } from "../../../../hooks/permissions/usePermissions";
import { PARTNER_FEATURES_QUERY_KEY } from "../../constants";
import {
  PartnerFeatureInterface,
  PartnerFeatureRoundUpInterface,
} from "../../types";

interface PartnerFeatureRoundUpProps extends PartnerFeatureInterface {
  roundUp: PartnerFeatureRoundUpInterface | null;
  orgId: string | undefined;
}

export default function PartnerFeatureRoundUp({
  orgId,
  partnerId,
  enabled,
  needsSetup,
  roundUp,
}: PartnerFeatureRoundUpProps) {
  const { hasPermission } = usePermissions();
  const canChangeRoundUp = hasPermission(
    PERMISSIONS_FEATURE_MANAGEMENT.ROUNDUP_TOGGLE
  );
  const canViewRoundUp = hasPermission(
    PERMISSIONS_FEATURE_MANAGEMENT.ROUNDUP_VIEW
  );

  const updateRoundUpsFeature = useUpdatePartnerRoundUpsFeature();
  const queryClient = useQueryClient();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onHideConfirmModal = () => setShowConfirmModal(false);

  const onEnableFeature = async () => {
    setSubmitting(true);

    return updateRoundUpsFeature
      .mutateAsync({
        partnerId,
        isActive: !!roundUp,
        roundUp,
      })
      .then(() => {
        queryClient.invalidateQueries([
          PARTNER_FEATURES_QUERY_KEY,
          orgId,
          partnerId,
        ]);
        onHideConfirmModal();
        setSubmitting(false);
      })
      .catch(() => {
        onHideConfirmModal();
        setSubmitting(false);
        toast.error("Failed to turn on Round Up. Please try again.");
      });
  };

  const onDisableFeature = async (
    form: FormApi<Record<string, any>, Partial<Record<string, any>>>
  ) => {
    return updateRoundUpsFeature
      .mutateAsync({
        partnerId,
        isActive: false,
        roundUp,
      })
      .then(() => {
        queryClient.invalidateQueries([
          PARTNER_FEATURES_QUERY_KEY,
          orgId,
          partnerId,
        ]);
      })
      .catch(() => {
        queryClient.invalidateQueries([
          PARTNER_FEATURES_QUERY_KEY,
          orgId,
          partnerId,
        ]);
        form.reset();
        toast.error("Failed to turn off Round Up. Please try again.");
      });
  };

  const initialValues = useMemo(
    () => ({
      roundup_enabled: enabled,
    }),
    [enabled]
  );

  const onSubmit = () => {};

  return (
    <div className="flex bg-gray-50 p-4 rounded-lg">
      <div className="flex-1 flex-row">
        <Typography as="body-1" className="font-semibold">
          Round Up
        </Typography>
        {!needsSetup && canViewRoundUp && (
          <Link
            to={"../" + ROUTES.ROUNDUPS + window.location.search}
            className="text-xs text-gray-500 underline"
          >
            Manage Round Ups
          </Link>
        )}
      </div>
      <div className="flex items-center">
        {needsSetup && canViewRoundUp ? (
          <Link
            to={"../" + ROUTES.ROUNDUPS + window.location.search}
            className="text-xs text-gray-500 underline"
          >
            Requires Setup - Add Round Up
          </Link>
        ) : (
          <Form
            onSubmit={onSubmit}
            subscription={{}}
            initialValues={initialValues}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="roundup_enabled"
                  type="checkbox"
                  render={({ input, meta }) => {
                    return (
                      <>
                        <Switch
                          label={input.checked ? "On" : "Off"}
                          labelPosition="left"
                          disabled={!canChangeRoundUp}
                          input={{
                            ...input,
                            onChange: (e) => {
                              if (e.target.checked === false) {
                                input.onChange(e);
                                onDisableFeature(form);
                              } else {
                                setShowConfirmModal(true);
                              }
                            },
                          }}
                          meta={meta}
                          name="roundup_enabled"
                          className="mb-0"
                        />
                      </>
                    );
                  }}
                />
              </form>
            )}
          />
        )}
      </div>

      {showConfirmModal ? (
        <ActionModal
          title="Are you sure you want to enable Round Up in the digital experience?"
          content={""}
          onClose={onHideConfirmModal}
          footer={
            <div>
              <SecondaryButton
                className="py-1.5 px-4 mr-2"
                onClick={onHideConfirmModal}
              >
                Not now
              </SecondaryButton>
              <PrimaryButton
                className="py-1.5 px-4"
                disabled={submitting}
                onClick={onEnableFeature}
              >
                Yes, turn on
              </PrimaryButton>
            </div>
          }
        />
      ) : null}
    </div>
  );
}
