import { useMutation } from "@tanstack/react-query";
import { PartnerFeatureRoundUpInterface } from "../../features/FeatureManagement/types";
import { updatePartnerRoundUpsFeature } from "../../services/features";

export default function useUpdatePartnerRoundUpsFeature() {
  return useMutation(
    ({
      partnerId,
      isActive,
      roundUp,
    }: {
      partnerId: string;
      isActive: boolean;
      roundUp: PartnerFeatureRoundUpInterface | null;
    }) => updatePartnerRoundUpsFeature(partnerId, isActive, roundUp)
  );
}
