import { Day } from "../types";
export default function isSameHours(days: Day[] | undefined): boolean {
  if (!days || days.length === 0) {
    return true;
  }

  if (days.length < 7) {
    return false;
  }

  let sameHours = true;
  const firstDay = JSON.stringify(days[0].shifts);

  for (let i = 1; i < days.length; i++) {
    const day = JSON.stringify(days[i].shifts);
    if (day !== firstDay) {
      sameHours = false;
      break;
    }
  }

  return sameHours;
}
