import { Link } from "react-router-dom";
import Typography from "../../../../components/Typography";
import { ROUTES } from "../../../../constants/routes";
import { PartnerFeatureInterface } from "../../types";
import { DiscountToggle } from "../../../../components/DiscountToggle/DiscountToggle";
import { usePermissions } from "../../../../hooks/permissions/usePermissions";
import { PERMISSIONS_FEATURE_MANAGEMENT } from "../../../../constants/permissions";

interface PartnerFeatureDiscountsProps extends PartnerFeatureInterface {
  orgId: string | undefined;
}

export default function PartnerFeatureDiscounts({
  orgId,
  partnerId,
  enabled,
  needsSetup,
}: PartnerFeatureDiscountsProps) {
  const { hasPermission } = usePermissions();

  const canViewDiscounts = hasPermission(
    PERMISSIONS_FEATURE_MANAGEMENT.DISCOUNT_VIEW
  );

  return (
    <div className="flex bg-gray-50 p-4 rounded-lg">
      <div className="flex-1 flex-row">
        <Typography as="body-1" className="font-semibold">
          Discounts
        </Typography>
        {!needsSetup && canViewDiscounts && (
          <Link
            to={"../" + ROUTES.DISCOUNTS + window.location.search}
            className="text-xs text-gray-500 underline"
          >
            Manage Discounts
          </Link>
        )}
      </div>
      <div className="flex items-center">
        <DiscountToggle orgId={orgId} partnerId={partnerId} enabled={enabled} />
      </div>
    </div>
  );
}
